import React from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import PlaceBet from "./components/PlaceBet";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import Timer from "./components/Timer";
import CasinoVideo from "./components/CasinoVideo";
export default function TeenpattiOneDay() {
  const GAME_ID = "TEEN";
  const VIDEO_ID = CASINO_VIDEO.teenPatti1Day;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [selectedTab, setSelectedTab] = React.useState("game");
  return (
    <app-casino-match-detail
      _nghost-ng-c3502172659=""
      className=" ng-star-inserted"
    >
      <div className="main-container ">
        <div className="center-main-container casino-page  ng-star-inserted">
          <div className="center-container ">
            <div className="casino-page-container  teenpatti1day">
              <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-5">
                <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-5">
                  <li
                    onClick={() => setSelectedTab("game")}
                    className="nav-item ng-tns-c3502172659-5"
                  >
                    <div
                      className={`ng-tns-c3502172659-5 
                      ${selectedTab === "game" && "active"}
                      nav-link`}
                    >
                      Game
                    </div>
                  </li>
                  <li
                    onClick={() => setSelectedTab("placedBet")}
                    className="nav-item ng-tns-c3502172659-5"
                  >
                    <div
                      className={`ng-tns-c3502172659-5 
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                    >
                      {" "}
                      Placed Bet ({casinoBets?.data?.response?.length}){" "}
                    </div>
                  </li>
                </ul>
                <div className="pe-2 ng-tns-c3502172659-5">
                  <span className="casino-rid ng-tns-c3502172659-5">
                    <a className="d-block ng-tns-c3502172659-5">
                      <small className="ng-tns-c3502172659-5">Rules</small>
                    </a>{" "}
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-5 ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </span>
                </div>
              </div>
              <div className="casino-header ">
                <span className="casino-name ">
                  {" "}
                  1 Day Teen Patti
                  <a className="ms-1 d-xl-inline d-none ">
                    <small className="">Rules</small>
                  </a>
                </span>
                <span className="casino-rid d-none d-xl-inline-block ">
                  <small className="">
                    Round ID:{" "}
                    <span className=" ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </small>
                  <span className=" ng-star-inserted">
                    {" "}
                    | Min:
                    <span className=" ng-star-inserted"> 100 </span>| Max:
                    <span className=" ng-star-inserted"> 500000 </span>
                  </span>
                </span>
              </div>

              <div className=" ng-star-inserted">
                <div className="casino-video ">
                  <div className="video-box-container ">
                    <div className="casino-video-box  ng-star-inserted">
                      <CasinoVideo id={VIDEO_ID} />
                    </div>
                  </div>
                  <div className="ng-tns-c3502172659-7 ng-star-inserted" />

                  <Timer value={casinoData?.data?.response?.autoTime} />
                  <div className=" ng-star-inserted">
                    <div className="casino-video-cards  ng-star-inserted">
                      <div className=" ng-star-inserted">
                        <h5 className=" ng-star-inserted">PLAYER A</h5>
                        <div className="flip-card-container  ng-star-inserted">
                          {casinoData?.data?.response?.cards[0]?.map((item) => (
                            <div className="me-lg-2 me-1  ng-star-inserted">
                              <img
                                className=""
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className=" ng-star-inserted">
                        <h5 className=" ng-star-inserted">PLAYER B</h5>
                        <div className="flip-card-container  ng-star-inserted">
                          {casinoData?.data?.response?.cards[1]?.map((item) => (
                            <div className="me-lg-2 me-1  ng-star-inserted">
                              <img
                                className=""
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="casino-detail ">
                  <div className="casino-table  ng-star-inserted">
                    <div className="casino-table-box ">
                      <div className="casino-table-left-box w-100 ">
                        <div className="casino-table-header ">
                          <div className="casino-nation-detail ">
                            <div className="min_max_odds justify-content-start d-lg-none d-flex ">
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    100{" "}
                                  </span>
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    500000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="casino-odds-box back ">Back</div>
                          <div className="casino-odds-box lay ">Lay</div>
                        </div>
                        <div className="casino-table-body ">
                          {casinoData?.data?.response?.oddDetailsDTOS?.map(
                            (item) => (
                              <div className="casino-table-row  ng-star-inserted">
                                <div className="casino-nation-detail ">
                                  <div className="casino-nation-name ">
                                    {" "}
                                    {item.runnerName} <p className="">0</p>
                                  </div>
                                </div>
                                <div
                                  className={`casino_odds_row ${(casinoData?.data
                                    ?.response?.autoTime < 5 ||
                                    item?.status === "SUSPENDED") &&
                                      "suspended-box"}`}
                                >
                                  <div
                                    onClick={() =>
                                      setBet(
                                        item?.runnerName,
                                        casinoData,
                                        dispatch
                                      )
                                    }
                                    className="casino-odds-box back "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity(
                                        item?.runnerName,
                                        casinoData
                                      )}
                                    </span>
                                    <p className="">
                                      {getOddForEntity(
                                        item?.runnerName,
                                        casinoData,
                                        false,
                                        true
                                      )}{" "}
                                    </p>
                                  </div>
                                  <div
                                    onClick={() =>
                                      setBet(
                                        item?.runnerName,
                                        casinoData,
                                        dispatch,
                                        "lay"
                                      )
                                    }
                                    className="casino-odds-box lay "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity(
                                        item?.runnerName,
                                        casinoData,
                                        false
                                      )}
                                    </span>
                                    <p className="">
                                      {" "}
                                      {getOddForEntity(
                                        item?.runnerName,
                                        casinoData,
                                        false,
                                        true
                                      )}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="casino-last-result-title ">
                      <span className="">Last Result</span>
                      <span className="">
                        <a className="">View All</a>
                      </span>
                    </div>
                    <div className="casino-last-results ng-tns-c3502172659-7 ng-star-inserted">
                        {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                          (item) => (
                            <span
                              className={`ng-tns-c3502172659-5 result result-${
                                item?.mainWinner === "T" ? "b" : "a"
                              } ng-star-inserted`}
                            >
                              <span className="ng-tns-c3502172659-5">
                                {" "}
                                {item?.mainWinner}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                  </div>
                </div>
              </div>
              <div className=" d-none matchbettable w-100 ng-star-inserted">
                <div className="table-responsive ">
                  <table className="table ">
                    <thead className="">
                      <tr className="">
                        <th className="">Matched Bet</th>
                        <th className="text-end ">Odds</th>
                        <th className="text-end ">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlaceBet GAME_ID={GAME_ID} />
    </app-casino-match-detail>
  );
}
