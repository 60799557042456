import React, { useState } from "react";
import "./index.scoped.css";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import Timer from "./components/Timer";
import CasinoVideo from "./components/CasinoVideo";
import PlacedBet from "./components/PlacedBet";
export default function DragonTiger() {
  const GAME_ID = "DRAGON_TIGER_20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("DRAGON");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [selectedTab, setSelectedTab] = useState("game");
  return (
    <div
      style={{
        width: "100%",
      }}
      _nghost-ng-c3502172659
      className="ng-tns-c3502172659-5 ng-star-inserted"
    >
      <div className="main-container ng-tns-c3502172659-5">
        <div className="center-main-container casino-page ng-tns-c3502172659-5 ng-star-inserted">
          <div className="center-container ng-tns-c3502172659-5">
            <div className="casino-page-container ng-tns-c3502172659-5 dt20">
              <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-5">
                <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-5">
                  <li
                    onClick={() => setSelectedTab("game")}
                    className="nav-item ng-tns-c3502172659-5"
                  >
                    <div
                      className={`ng-tns-c3502172659-5 
                      ${selectedTab === "game" && "active"}
                      nav-link`}
                    >
                      Game
                    </div>
                  </li>
                  <li
                    onClick={() => setSelectedTab("placedBet")}
                    className="nav-item ng-tns-c3502172659-5"
                  >
                    <div
                      className={`ng-tns-c3502172659-5 
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                    >
                      {" "}
                      Placed Bet ({casinoBets?.data?.response?.length}){" "}
                    </div>
                  </li>
                </ul>
                <div className="pe-2 ng-tns-c3502172659-5">
                  <span className="casino-rid ng-tns-c3502172659-5">
                    <a className="d-block ng-tns-c3502172659-5">
                      <small className="ng-tns-c3502172659-5">Rules</small>
                    </a>{" "}
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-5 ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </span>
                </div>
              </div>
              <div className="casino-header ng-tns-c3502172659-5">
                <span className="casino-name ng-tns-c3502172659-5">
                  {" "}
                  20-20 DRAGON TIGER
                  <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-5">
                    <small className="ng-tns-c3502172659-5">Rules</small>
                  </a>
                </span>
                <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-5">
                  <small className="ng-tns-c3502172659-5">
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-5 ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </small>
                  <span className="ng-tns-c3502172659-5 ng-star-inserted">
                    {" "}
                    | Min: 100 | Max: 100000
                  </span>
                </span>
              </div>

              {selectedTab === "game" ? (
                <div className="ng-tns-c3502172659-5 ng-star-inserted">
                  <div className="casino-video ng-tns-c3502172659-5">
                    <div className="video-box-container ng-tns-c3502172659-5">
                      <div className="casino-video-box ng-tns-c3502172659-5 ng-star-inserted">
                        <CasinoVideo id={CASINO_VIDEO.dragonTiger2020} />
                      </div>
                    </div>
                    <div className="ng-tns-c3502172659-5 ng-star-inserted">
                      <div className="casino-video-cards ng-tns-c3502172659-5 ng-star-inserted">
                        <div className="flip-card-container ng-tns-c3502172659-5">
                          {casinoData?.data?.response?.cards[0]?.map(
                            (item) => (
                              <div className="me-lg-2 me-1 ng-tns-c3502172659-5 ng-star-inserted">
                                <img
                                  className="ng-tns-c3502172659-5"
                                  src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    <Timer value={casinoData?.data?.response?.autoTime} />
                  </div>

                  <div className="casino-detail ng-tns-c3502172659-5">
                    <div className="casino-table ng-tns-c3502172659-5 ng-star-inserted">
                      <div className="d-lg-block d-none ng-tns-c3502172659-5">
                        <div className="casino-table-full-box ng-tns-c3502172659-5">
                          <div className="dt20-odd-box dt20dragon ng-tns-c3502172659-5">
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {" "}
                              2{" "}
                            </div>
                            <div
                              onClick={() =>
                                setBet("DRAGON", casinoData, dispatch)
                              }
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Dragon
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              {getOddForEntity("DRAGON", casinoData)}{" "}
                            </p>
                          </div>
                          <div
                            onClick={() =>
                              setBet("TIE", casinoData, dispatch)
                            }
                            className="dt20-odd-box dt20tie ng-tns-c3502172659-5"
                          >
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {" "}
                              {getOddForEntity("TIE", casinoData)}{" "}
                            </div>
                            <div
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Tie
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div
                            onClick={() =>
                              setBet("TIGER", casinoData, dispatch)
                            }
                            className="dt20-odd-box dt20tiger ng-tns-c3502172659-5"
                          >
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {" "}
                              {getOddForEntity("TIGER", casinoData)}{" "}
                            </div>
                            <div
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Tiger
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div
                            onClick={() =>
                              setBet("PAIR", casinoData, dispatch)
                            }
                            className="dt20-odd-box dt20pair ng-tns-c3502172659-5"
                          >
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {" "}
                              {getOddForEntity("PAIR", casinoData)}{" "}
                            </div>
                            <div
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Pair
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5">
                            <span className="ng-tns-c3502172659-5">
                              <b className="ng-tns-c3502172659-5">Min: </b>
                              <span className="ng-tns-c3502172659-5">
                                {" "}
                                100{" "}
                              </span>
                            </span>
                            <span className="ng-tns-c3502172659-5">
                              <b className="ng-tns-c3502172659-5">Max: </b>
                              <span className="ng-tns-c3502172659-5">
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="casino-table-box mt-3 ng-tns-c3502172659-5">
                          <div className="casino-table-left-box ng-tns-c3502172659-5">
                            <h4 className="w-100 text-center mb-2 ng-tns-c3502172659-5">
                              DRAGON
                            </h4>
                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("DRAGON EVEN", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("DRAGON EVEN", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-5">
                                  Even
                                </span>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("DRAGON ODD", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("DRAGON ODD", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-5">
                                  Odd
                                </span>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5 ng-star-inserted">
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Min: </b>
                                <span className="ng-tns-c3502172659-5">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Max: </b>
                                <span className="ng-tns-c3502172659-5">
                                  {" "}
                                  100000
                                </span>
                              </span>
                            </div>

                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("DRAGON RED", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("DRAGON RED", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <div className="casino-odds ng-tns-c3502172659-5">
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                      />
                                    </span>
                                  </span>
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                      />
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("DRAGON BLACK", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("DRAGON BLACK", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <div className="casino-odds ng-tns-c3502172659-5">
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                      />
                                    </span>
                                  </span>
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                      />
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5 ng-star-inserted">
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Min: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100
                                </span>
                              </span>
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Max: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100000
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="casino-table-right-box ng-tns-c3502172659-5">
                            <h4 className="w-100 text-center mb-2 ng-tns-c3502172659-5">
                              TIGER
                            </h4>
                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("TIGER EVEN", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("TIGER EVEN", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-5">
                                  Even
                                </span>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("TIGER ODD", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("TIGER ODD", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-5">
                                  Odd
                                </span>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5 ng-star-inserted">
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Min: </b>
                                <span className="ng-tns-c3502172659-5">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Max: </b>
                                <span className="ng-tns-c3502172659-5">
                                  {" "}
                                  100000
                                </span>
                              </span>
                            </div>

                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("TIGER RED", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("TIGER RED", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <div className="casino-odds ng-tns-c3502172659-5">
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                      />
                                    </span>
                                  </span>
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                      />
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                              <div className="casino-odds text-center ng-tns-c3502172659-5">
                                {getOddForEntity("TIGER BLACK", casinoData)}
                              </div>
                              <div
                                onClick={() =>
                                  setBet("TIGER BLACK", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  "suspended-box"}`}
                              >
                                <div className="casino-odds ng-tns-c3502172659-5">
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                      />
                                    </span>
                                  </span>
                                  <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                    <span className="card-red ng-tns-c3502172659-5">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                      />
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                {" "}
                                0{" "}
                              </p>
                            </div>

                            <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5 ng-star-inserted">
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Min: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100
                                </span>
                              </span>
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Max: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-box mt-3 ng-tns-c3502172659-5">
                          <div className="casino-table-left-box ng-tns-c3502172659-5">
                            <div className="dt20cards ng-tns-c3502172659-5">
                              <h4 className="w-100 text-center mb-2 ng-tns-c3502172659-5">
                                {" "}
                                DRAGON 12.00{" "}
                              </h4>
                              {CARD_ARRAY?.map((item) => (
                                <div className="card-odd-box ng-tns-c3502172659-5 ng-star-inserted">
                                  <div
                                    onClick={() =>
                                      setBet(
                                        "DRAGON card " + item,
                                        casinoData,
                                        dispatch
                                      )
                                    }
                                    className={`ng-tns-c3502172659-5 ${casinoData
                                      ?.data?.response?.autoTime < 5 &&
                                      "suspended-box"}`}
                                  >
                                    <img
                                      className="ng-tns-c3502172659-5"
                                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                    />
                                  </div>
                                  <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                    0
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5">
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Min: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100
                                </span>
                              </span>
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Max: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100000
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="casino-table-right-box ng-tns-c3502172659-5">
                            <div className="dt20cards ng-tns-c3502172659-5">
                              <h4 className="w-100 text-center mb-2 ng-tns-c3502172659-5">
                                {" "}
                                TIGER 12.00{" "}
                              </h4>
                              {CARD_ARRAY?.map((item) => (
                                <div className="card-odd-box ng-tns-c3502172659-5 ng-star-inserted">
                                  <div
                                    onClick={() =>
                                      setBet(
                                        "TIGER Card " + item,
                                        casinoData,
                                        dispatch
                                      )
                                    }
                                    className={`ng-tns-c3502172659-5 ${casinoData
                                      ?.data?.response?.autoTime < 5 &&
                                      "suspended-box"}`}
                                  >
                                    <img
                                      className="ng-tns-c3502172659-5"
                                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                    />
                                  </div>
                                  <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                    0
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="col-md-12 min-max-dt20 text-end ng-tns-c3502172659-5">
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Min: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100
                                </span>
                              </span>
                              <span className="ng-tns-c3502172659-5">
                                <b className="ng-tns-c3502172659-5">Max: </b>
                                <span className="ng-tns-c3502172659-5">
                                  100000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-none d-block ng-tns-c3502172659-5">
                        <div className="casino-table-full-box ng-tns-c3502172659-5">
                          <div className="dt20-odd-box dt20dragon ng-tns-c3502172659-5">
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {getOddForEntity("Dragon", casinoData)}
                            </div>
                            <div
                              onClick={() =>
                                setBet("Dragon", casinoData, dispatch)
                              }
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Dragon
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div className="dt20-odd-box dt20tie ng-tns-c3502172659-5">
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {getOddForEntity("Tie", casinoData)}
                            </div>
                            <div
                              onClick={() =>
                                setBet("Tie", casinoData, dispatch)
                              }
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Tie
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div className="dt20-odd-box dt20tiger ng-tns-c3502172659-5">
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {getOddForEntity("Tiger", casinoData)}
                            </div>
                            <div
                              onClick={() =>
                                setBet("Tiger", casinoData, dispatch)
                              }
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Tiger
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div className="dt20-odd-box dt20pair ng-tns-c3502172659-5">
                            <div className="casino-odds text-center ng-tns-c3502172659-5">
                              {getOddForEntity("Pair", casinoData)}
                            </div>
                            <div
                              onClick={() =>
                                setBet("Pair", casinoData, dispatch)
                              }
                              className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <span className="casino-odds ng-tns-c3502172659-5">
                                Pair
                              </span>
                            </div>
                            <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                              {" "}
                              0.00{" "}
                            </p>
                          </div>
                          <div className="col-12 min-max-dt20 text-end w- ng-tns-c3502172659-5">
                            <span className="ng-tns-c3502172659-5">
                              <b className="ng-tns-c3502172659-5">Min: </b>
                              <span className="ng-tns-c3502172659-5">
                                100
                              </span>
                            </span>
                            <span className="ng-tns-c3502172659-5">
                              <b className="ng-tns-c3502172659-5">Max: </b>
                              <span className="ng-tns-c3502172659-5">
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                        <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-5">
                          <li
                            onClick={() => setSelectedType("Dragon")}
                            className="nav-item ng-tns-c3502172659-5"
                          >
                            <div
                              style={{}}
                              className={`ng-tns-c3502172659-5 
                                ${selectedType === "Dragon" ? "active" : ""}
                                nav-link`}
                            >
                              {" "}
                              Dragon{" "}
                            </div>
                          </li>
                          <li
                            onClick={() => setSelectedType("Tiger")}
                            className="nav-item ng-tns-c3502172659-5"
                          >
                            <div
                              className={`ng-tns-c3502172659-5 
                                ${selectedType === "Tiger" ? "active" : ""}
                                nav-link`}
                            >
                              {" "}
                              Tiger{" "}
                            </div>
                          </li>
                        </ul>
                        <div className="ng-tns-c3502172659-5 ng-star-inserted">
                          <div className="casino-table-box mt-3 ng-tns-c3502172659-5">
                            <div className="casino-table-left-box ng-tns-c3502172659-5">
                              <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                                <div className="casino-odds text-center ng-tns-c3502172659-5">
                                  {getOddForEntity(
                                    selectedType + " even",
                                    casinoData
                                  )}
                                </div>
                                <div
                                  onClick={() =>
                                    setBet(
                                      selectedType + " even",
                                      casinoData,
                                      dispatch
                                    )
                                  }
                                  className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                    ?.data?.response?.autoTime < 5 &&
                                    "suspended-box"}`}
                                >
                                  <span className="casino-odds ng-tns-c3502172659-5">
                                    Even
                                  </span>
                                </div>
                                <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                  {" "}
                                  0{" "}
                                </p>
                              </div>

                              <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                                <div className="casino-odds text-center ng-tns-c3502172659-5">
                                  {getOddForEntity(
                                    selectedType + " odd",
                                    casinoData
                                  )}
                                </div>
                                <div
                                  onClick={() =>
                                    setBet(
                                      selectedType + " odd",
                                      casinoData,
                                      dispatch
                                    )
                                  }
                                  className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                    ?.data?.response?.autoTime < 5 &&
                                    "suspended-box"}`}
                                >
                                  <span className="casino-odds ng-tns-c3502172659-5">
                                    Odd
                                  </span>
                                </div>
                                <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                  {" "}
                                  0{" "}
                                </p>
                              </div>

                              <div className="col-12 min-max-dt20 text-end ng-tns-c3502172659-5 ng-star-inserted">
                                <span className="ng-tns-c3502172659-5">
                                  <b className="ng-tns-c3502172659-5">
                                    Min:{" "}
                                  </b>
                                  <span className="ng-tns-c3502172659-5">
                                    100
                                  </span>
                                </span>
                                <span className="ng-tns-c3502172659-5">
                                  <b className="ng-tns-c3502172659-5">
                                    Max:{" "}
                                  </b>
                                  <span className="ng-tns-c3502172659-5">
                                    100000
                                  </span>
                                </span>
                              </div>

                              <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                                <div className="casino-odds text-center ng-tns-c3502172659-5">
                                  {getOddForEntity(
                                    selectedType + " black",
                                    casinoData
                                  )}
                                </div>
                                <div
                                  onClick={() =>
                                    setBet(
                                      selectedType + " black",
                                      casinoData,
                                      dispatch
                                    )
                                  }
                                  className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                    ?.data?.response?.autoTime < 5 &&
                                    "suspended-box"}`}
                                >
                                  <div className="casino-odds ng-tns-c3502172659-5">
                                    <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                      <span className="card-red ng-tns-c3502172659-5">
                                        <img
                                          className="ng-tns-c3502172659-5"
                                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                        />
                                      </span>
                                    </span>
                                    <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                      <span className="card-red ng-tns-c3502172659-5">
                                        <img
                                          className="ng-tns-c3502172659-5"
                                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                        />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                  {" "}
                                  0{" "}
                                </p>
                              </div>

                              <div className="dt20-odd-box dt20odds ng-tns-c3502172659-5 ng-star-inserted">
                                <div className="casino-odds text-center ng-tns-c3502172659-5">
                                  {getOddForEntity(
                                    selectedType + " red",
                                    casinoData
                                  )}
                                </div>
                                <div
                                  onClick={() =>
                                    setBet(
                                      selectedType + " red",
                                      casinoData,
                                      dispatch
                                    )
                                  }
                                  className={`ng-tns-c3502172659-5 back casino-odds-box casino-odds-box-theme ${casinoData
                                    ?.data?.response?.autoTime < 5 &&
                                    "suspended-box"}`}
                                >
                                  <div className="casino-odds ng-tns-c3502172659-5">
                                    <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                      <span className="card-red ng-tns-c3502172659-5">
                                        <img
                                          className="ng-tns-c3502172659-5"
                                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                        />
                                      </span>
                                    </span>
                                    <span className="card-icon ms-1 ng-tns-c3502172659-5">
                                      <span className="card-red ng-tns-c3502172659-5">
                                        <img
                                          className="ng-tns-c3502172659-5"
                                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                        />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                  {" "}
                                  0{" "}
                                </p>
                              </div>

                              <div className="col-12 min-max-dt20 text-end ng-tns-c3502172659-5 ng-star-inserted">
                                <span className="ng-tns-c3502172659-5">
                                  <b className="ng-tns-c3502172659-5">
                                    Min:{" "}
                                  </b>
                                  <span className="ng-tns-c3502172659-5">
                                    100
                                  </span>
                                </span>
                                <span className="ng-tns-c3502172659-5">
                                  <b className="ng-tns-c3502172659-5">
                                    Max:{" "}
                                  </b>
                                  <span className="ng-tns-c3502172659-5">
                                    100000
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-table-box mt-3 ng-tns-c3502172659-5">
                            <div className="casino-table-left-box ng-tns-c3502172659-5">
                              <div className="dt20cards ng-tns-c3502172659-5">
                                <div className="casino-odds text-center w-100 mb-1 ng-tns-c3502172659-5">
                                  {" "}
                                  12.00{" "}
                                </div>
                                {CARD_ARRAY?.map((item) => (
                                  <div
                                    onClick={() =>
                                      setBet(
                                        selectedType + " Card " + item,
                                        casinoData,
                                        dispatch
                                      )
                                    }
                                    className="card-odd-box ng-tns-c3502172659-5 ng-star-inserted"
                                  >
                                    <div className="={`ng-tns-c3502172659-5 ${casinoData?.data?.response?.autoTime<5 &&suspended-box}`}">
                                      <img
                                        className="ng-tns-c3502172659-5"
                                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                      />
                                    </div>
                                    <p className="w-100 text-center casino-odds-dwn ng-tns-c3502172659-5">
                                      {" "}
                                      0{" "}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <div className="col-12 min-max-dt20 text-end ng-tns-c3502172659-5">
                                <span className="ng-tns-c3502172659-5">
                                  <b className="ng-tns-c3502172659-5">
                                    Min:{" "}
                                  </b>
                                  <span className="ng-tns-c3502172659-5">
                                    100
                                  </span>
                                </span>
                                <span className="ng-tns-c3502172659-5">
                                  <b className="ng-tns-c3502172659-5">
                                    Max:{" "}
                                  </b>
                                  <span className="ng-tns-c3502172659-5">
                                    100000
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ng-tns-c3502172659-5">
                      <div className="casino-last-result-title ng-tns-c3502172659-5">
                        <span className="ng-tns-c3502172659-5">
                          Last Result
                        </span>
                        <span className="ng-tns-c3502172659-5">
                          <a className="ng-tns-c3502172659-5">View All</a>
                        </span>
                      </div>
                      <div className="casino-last-results ng-tns-c3502172659-5 ng-star-inserted">
                        {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                          (item) => (
                            <span
                              className={`ng-tns-c3502172659-5 result result-${
                                item?.mainWinner === "T" ? "b" : "a"
                              } ng-star-inserted`}
                            >
                              <span className="ng-tns-c3502172659-5">
                                {" "}
                                {item?.mainWinner}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <PlacedBet bets={casinoBets?.data?.response} />
              )}
              <div className="ng-tns-c3502172659-5 d-none matchbettable w-100 ng-star-inserted">
                <div className="table-responsive ng-tns-c3502172659-5">
                  <table className="table ng-tns-c3502172659-5">
                    <thead className="ng-tns-c3502172659-5">
                      <tr className="ng-tns-c3502172659-5">
                        <th className="ng-tns-c3502172659-5">Matched Bet</th>
                        <th className="text-end ng-tns-c3502172659-5">
                          Odds
                        </th>
                        <th className="text-end ng-tns-c3502172659-5">
                          Stake
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar right-sidebar casino-right-sidebar ng-tns-c3502172659-5">
            <div className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c3502172659-5">
              <div className="sidebar-title ng-tns-c3502172659-5">
                <h4 className="ng-tns-c3502172659-5">Place Bet</h4>
              </div>
            </div>
            <div className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c3502172659-5">
              <div className="sidebar-title ng-tns-c3502172659-5">
                <h4 className="ng-tns-c3502172659-5">My Bet</h4>
                <a className="sideviewmore ng-tns-c3502172659-5">View more</a>
              </div>
              <div className="my-bets ng-tns-c3502172659-5">
                <div className="table-responsive w-100 ng-tns-c3502172659-5">
                  <table className="table ng-tns-c3502172659-5">
                    <thead className="ng-tns-c3502172659-5">
                      <tr className="ng-tns-c3502172659-5">
                        <th className="ng-tns-c3502172659-5">Matched Bet</th>
                        <th className="text-end ng-tns-c3502172659-5">
                          Odds
                        </th>
                        <th className="text-end ng-tns-c3502172659-5">
                          Stake
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c3502172659-5">
              <app-casino-game-rules
                className="ng-tns-c3502172659-5"
                _nghost-ng-c1761710207
              />
            </div>
          </div>
        </div>
      </div>
      <PlaceBet GAME_ID={GAME_ID} />
    </div>
  );
}
