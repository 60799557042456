import React, { useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useQuery } from "react-query";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import {
  CARD_ARRAY,
  cardArray,
  CASINO_VIDEO,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import { useDispatch } from "react-redux";
import PlacedBet from "./components/PlacedBet";
export default function AmarAkhbarAnthoany() {
  const GAME_ID = "AAA";
  const VIDEO_ID = CASINO_VIDEO.amarAkbarAnthony;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [selectedTab, setSelectedTab] = useState("game");
  return (
    <app-casino-match-detail
      _nghost-ng-c3502172659=""
      className=" ng-star-inserted"
    >
      <div className="main-container ">
        <div className="center-main-container casino-page  ng-star-inserted">
          <div className="center-container ">
            <div className="casino-page-container  aaa">
              <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-5">
                <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-5">
                  <li
                    onClick={() => setSelectedTab("game")}
                    className="nav-item ng-tns-c3502172659-5"
                  >
                    <div
                      className={`ng-tns-c3502172659-5 
                      ${selectedTab === "game" && "active"}
                      nav-link`}
                    >
                      Game
                    </div>
                  </li>
                  <li
                    onClick={() => setSelectedTab("placedBet")}
                    className="nav-item ng-tns-c3502172659-5"
                  >
                    <div
                      className={`ng-tns-c3502172659-5 
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                    >
                      {" "}
                      Placed Bet ({casinoBets?.data?.response?.length}){" "}
                    </div>
                  </li>
                </ul>
                <div className="pe-2 ng-tns-c3502172659-5">
                  <span className="casino-rid ng-tns-c3502172659-5">
                    <a className="d-block ng-tns-c3502172659-5">
                      <small className="ng-tns-c3502172659-5">Rules</small>
                    </a>{" "}
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-5 ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </span>
                </div>
              </div>
              <div className="casino-header ">
                <span className="casino-name ">
                  {" "}
                  Amar Akbar Anthony
                  <a className="ms-1 d-xl-inline d-none ">
                    <small className="">Rules</small>
                  </a>
                </span>
                <span className="casino-rid d-none d-xl-inline-block ">
                  <small className="">
                    Round ID:{" "}
                    <span className=" ng-star-inserted">27.241007205229</span>
                  </small>
                  <span className=" ng-star-inserted">
                    {" "}
                    | Min: 100 | Max: 300000
                  </span>
                </span>
              </div>
              {selectedTab === "game" ? (
                <div className=" ng-star-inserted">
                  <div className="casino-video ">
                    <div className="video-box-container ">
                      <div className="casino-video-box  ng-star-inserted">
                        <CasinoVideo id={VIDEO_ID} />
                      </div>
                    </div>
                    <div className=" ng-star-inserted">
                      <div className="casino-video-cards  ng-star-inserted">
                        <h5 className="">Card</h5>
                        <div className="flip-card-container ">
                          <div className="me-lg-2 me-1  ng-star-inserted">
                            <img
                              className=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Timer value={casinoData?.data?.response?.autoTime} />
                  </div>

                  <div className="casino-detail ">
                    <div className="casino-table  ng-star-inserted">
                      <div className="casino-table-box casino-tavle-box-background d-lg-flex d-none ">
                        <div className="w-100 text-end ">
                          <div className="min_max_collapse ">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange2aaa1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2aaa1"
                              className="minmax-btn collapsed "
                            >
                              <i className="fas fa-info-circle " />
                            </a>
                            <div
                              id="collapserange2aaa1"
                              className="range-collpase collapse "
                            >
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b>
                                <span className=""> 100</span>
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    300000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="casino-odd-box-container  ng-star-inserted">
                          <div className="casino-nation-name ">
                            <span className="text-danger  ng-star-inserted">
                              A.
                            </span>
                            Amar{" "}
                            <div className="casino-nation-book d-md-none " />
                          </div>
                          <div className=" casino-odds-box-row">
                            <div
                              onClick={() =>
                                setBet("amar", casinoData, dispatch)
                              }
                              className="casino-odds-box back "
                            >
                              <span className="casino-odds ">
                                {getOddForEntity("amar", casinoData)}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                setBet("amar", casinoData, dispatch, "lay")
                              }
                              className="casino-odds-box lay "
                            >
                              <span className="casino-odds ">
                                {getOddForEntity("amar", casinoData, false)}
                              </span>
                            </div>
                          </div>
                          <div className="casino-nation-book text-center d-none d-md-block w-100 mb-2 ">
                            {" "}
                            0{" "}
                          </div>
                        </div>
                        <div className="casino-odd-box-container  ng-star-inserted">
                          <div className="casino-nation-name ">
                            <span className="text-danger  ng-star-inserted">
                              B.
                            </span>
                            Akbar{" "}
                            <div className="casino-nation-book d-md-none " />
                          </div>
                          <div className=" casino-odds-box-row">
                            <div
                              onClick={() =>
                                setBet("akbar", casinoData, dispatch)
                              }
                              className="casino-odds-box back "
                            >
                              <span className="casino-odds ">
                                {getOddForEntity("akbar", casinoData)}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                setBet("akbar", casinoData, dispatch, "lay")
                              }
                              className="casino-odds-box lay "
                            >
                              <span className="casino-odds ">
                                {getOddForEntity("akbar", casinoData, false)}
                              </span>
                            </div>
                          </div>
                          <div className="casino-nation-book text-center d-none d-md-block w-100 mb-2 ">
                            {" "}
                            0{" "}
                          </div>
                        </div>
                        <div className="casino-odd-box-container  ng-star-inserted">
                          <div className="casino-nation-name ">
                            <span className="text-danger  ng-star-inserted">
                              C.
                            </span>
                            Anthony{" "}
                            <div className="casino-nation-book d-md-none " />
                          </div>
                          <div className=" casino-odds-box-row">
                            <div
                              onClick={() =>
                                setBet("anthony", casinoData, dispatch)
                              }
                              className="casino-odds-box back "
                            >
                              <span className="casino-odds ">
                                {getOddForEntity("anthony", casinoData)}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                setBet("anthony", casinoData, dispatch, "lay")
                              }
                              className="casino-odds-box lay "
                            >
                              <span className="casino-odds ">
                                {getOddForEntity("anthony", casinoData, false)}
                              </span>
                            </div>
                          </div>
                          <div className="casino-nation-book text-center d-none d-md-block w-100 mb-2 ">
                            {" "}
                            0{" "}
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-none d-block w-100 ">
                        <div className="casino-table-box mobile-akbar ">
                          <div className="w-100 ">
                            <div className="casino-table-header ">
                              <div className="casino-nation-detail ">
                                <div className="min_max_odds justify-content-start d-lg-none d-flex ">
                                  <span className="min_max_span ms-0 ">
                                    <b className="">Min:</b>
                                    <span className=""> 100</span>
                                  </span>
                                  <span className="min_max_span ms-1 ">
                                    <b className="">Max:</b>
                                    <span className="">
                                      <span className=" ng-star-inserted">
                                        {" "}
                                        300000{" "}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="casino-table-body ">
                              <div className="casino-table-row  ng-star-inserted">
                                <div className="casino-nation-detail ">
                                  <div className="casino-nation-name ">
                                    <span className="text-danger  ng-star-inserted">
                                      A.
                                    </span>
                                    Amar <p className="">0</p>
                                  </div>
                                </div>
                                <div className=" casino_odds_row">
                                  <div
                                    onClick={() =>
                                      setBet("amar", casinoData, dispatch)
                                    }
                                    className="casino-odds-box back "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity("amar", casinoData)}
                                    </span>
                                  </div>
                                  <div
                                    onClick={() =>
                                      setBet(
                                        "amar",
                                        casinoData,
                                        dispatch,
                                        "lay"
                                      )
                                    }
                                    className="casino-odds-box lay "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity(
                                        "amar",
                                        casinoData,
                                        false
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="casino-table-row  ng-star-inserted">
                                <div className="casino-nation-detail ">
                                  <div className="casino-nation-name ">
                                    <span className="text-danger  ng-star-inserted">
                                      B.
                                    </span>
                                    Akbar <p className="">0</p>
                                  </div>
                                </div>
                                <div className=" casino_odds_row">
                                  <div
                                    onClick={() =>
                                      setBet("akbar", casinoData, dispatch)
                                    }
                                    className="casino-odds-box back "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity("akbar", casinoData)}
                                    </span>
                                  </div>
                                  <div
                                    onClick={() =>
                                      setBet(
                                        "akbar",
                                        casinoData,
                                        dispatch,
                                        "lay"
                                      )
                                    }
                                    className="casino-odds-box lay "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity(
                                        "akbar",
                                        casinoData,
                                        false
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="casino-table-row  ng-star-inserted">
                                <div className="casino-nation-detail ">
                                  <div className="casino-nation-name ">
                                    <span className="text-danger  ng-star-inserted">
                                      C.
                                    </span>
                                    Anthony <p className="">0</p>
                                  </div>
                                </div>
                                <div className=" casino_odds_row">
                                  <div
                                    onClick={() =>
                                      setBet("anthony", casinoData, dispatch)
                                    }
                                    className="casino-odds-box back "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity("anthony", casinoData)}
                                    </span>
                                  </div>
                                  <div
                                    onClick={() =>
                                      setBet(
                                        "anthony",
                                        casinoData,
                                        dispatch,
                                        "lay"
                                      )
                                    }
                                    className="casino-odds-box lay "
                                  >
                                    <span className="casino-odds ">
                                      {getOddForEntity(
                                        "anthony",
                                        casinoData,
                                        false
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-box casino-table-box-margin ">
                        <div className="casino-table-left-box ">
                          <div className="aaa-odd-box  ng-star-inserted">
                            <div className="w-100 text-end  ng-star-inserted">
                              <div className="min_max_collapse ">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed "
                                  href="#collapserange2aaa4"
                                  aria-controls="collapserange2aaa4"
                                >
                                  <i className="fas fa-info-circle " />
                                </a>
                                <div
                                  className="range-collpase collapse "
                                  id="collapserange2aaa4"
                                >
                                  <span className="min_max_span ms-0 ">
                                    <b className="">Min:</b>
                                    <span className=""> 100</span>
                                  </span>
                                  <span className="min_max_span ms-1 ">
                                    <b className="">Max:</b>
                                    <span className="">
                                      <span className=" ng-star-inserted">
                                        {" "}
                                        25000{" "}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="casino-odds text-center ">
                              {" "}
                              {getOddForEntity("even", casinoData)}{" "}
                            </div>
                            <div className=" back casino-odds-box casino-odds-box-theme">
                              <span className="casino-odds ">Even</span>
                            </div>
                            <div className="casino-nation-book text-center ">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="aaa-odd-box  ng-star-inserted">
                            <div className="casino-odds text-center ">
                              {" "}
                              {getOddForEntity("odd", casinoData)}{" "}
                            </div>
                            <div className=" back casino-odds-box casino-odds-box-theme">
                              <span className="casino-odds ">Odd</span>
                            </div>
                            <div className="casino-nation-book text-center ">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-center-box ">
                          <div className="aaa-odd-box  ng-star-inserted">
                            <div className="w-100 text-end  ng-star-inserted">
                              <div className="min_max_collapse ">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed "
                                  href="#collapserange2aaa6"
                                  aria-controls="collapserange2aaa6"
                                >
                                  <i className="fas fa-info-circle " />
                                </a>
                                <div
                                  className="range-collpase collapse "
                                  id="collapserange2aaa6"
                                >
                                  <span className="min_max_span ms-0 ">
                                    <b className="">Min:</b>
                                    <span className=""> 100</span>
                                  </span>
                                  <span className="min_max_span ms-1 ">
                                    <b className="">Max:</b>
                                    <span className="">
                                      <span className=" ng-star-inserted">
                                        {" "}
                                        25000{" "}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="casino-odds text-center ">
                              {getOddForEntity("red", casinoData)}{" "}
                            </div>
                            <div
                              onClick={() =>
                                setBet("red", casinoData, dispatch)
                              }
                              className=" back casino-odds-box casino-odds-box-theme"
                            >
                              <div className="casino-odds  ng-star-inserted">
                                <span className="card-icon ms-1 ">
                                  <span className="card-red ">
                                    <img
                                      className=""
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                    />
                                  </span>
                                </span>
                                <span className="card-icon ms-1 ">
                                  <span className="card-red ">
                                    <img
                                      className=""
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="casino-nation-book text-center ">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="aaa-odd-box  ng-star-inserted">
                            <div className="casino-odds text-center ">
                              {" "}
                              {getOddForEntity("black", casinoData)}{" "}
                            </div>
                            <div
                              onClick={() =>
                                setBet("black", casinoData, dispatch)
                              }
                              className=" back casino-odds-box casino-odds-box-theme"
                            >
                              <div className="casino-odds  ng-star-inserted">
                                <span className="card-icon ms-1 ">
                                  <span className="card-black ">
                                    <img
                                      className=""
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                    />
                                  </span>
                                </span>
                                <span className="card-icon ms-1 ">
                                  <span className="card-black ">
                                    <img
                                      className=""
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="casino-nation-book text-center ">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-right-box ">
                          <div className="aaa-odd-box  ng-star-inserted">
                            <div className="w-100 text-end  ng-star-inserted">
                              <div className="min_max_collapse ">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed "
                                  href="#collapserange2aaa21"
                                  aria-controls="collapserange2aaa21"
                                >
                                  <i className="fas fa-info-circle " />
                                </a>
                                <div
                                  className="range-collpase collapse "
                                  id="collapserange2aaa21"
                                >
                                  <span className="min_max_span ms-0 ">
                                    <b className="">Min:</b>
                                    <span className=""> 100</span>
                                  </span>
                                  <span className="min_max_span ms-1 ">
                                    <b className="">Max:</b>
                                    <span className="">
                                      <span className=" ng-star-inserted">
                                        {" "}
                                        100000{" "}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="casino-odds text-center ">
                              {" "}
                              {getOddForEntity("under 7", casinoData)}{" "}
                            </div>
                            <div
                              onClick={() =>
                                setBet("under 7", casinoData, dispatch)
                              }
                              className=" back casino-odds-box casino-odds-box-theme"
                            >
                              <span className="casino-odds ">Under 7</span>
                            </div>
                            <div className="casino-nation-book text-center ">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="aaa-odd-box  ng-star-inserted">
                            <div className="casino-odds text-center ">
                              {" "}
                              {getOddForEntity("over 7", casinoData)}{" "}
                            </div>
                            <div
                              onClick={() =>
                                setBet("over 7", casinoData, dispatch)
                              }
                              className=" back casino-odds-box casino-odds-box-theme"
                            >
                              <span className="casino-odds ">Over 7</span>
                            </div>
                            <div className="casino-nation-book text-center ">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-full-box aaacards mt-3 ">
                        <div className="w-100 text-end ">
                          <div className="min_max_collapse ">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange2"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2"
                              className="minmax-btn collapsed "
                            >
                              <i className="fas fa-info-circle " />
                            </a>
                            <div
                              id="collapserange2"
                              className="range-collpase collapse "
                            >
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b> 100{" "}
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className=" ng-star-inserted">
                                  {" "}
                                  50000{" "}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <h4 className="w-100 text-center mb-2 ">
                          <b className=""> 12 </b>
                        </h4>
                        {CARD_ARRAY?.map((card) => (
                          <div
                            onClick={() =>
                              setBet("Card " + card, casinoData, dispatch)
                            }
                            className="card-odd-box  ng-star-inserted"
                          >
                            <div className="">
                              <img
                                className=" ng-star-inserted"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                              />
                            </div>
                            <div className="casino-nation-book "> 0 </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="">
                      <div className="casino-last-result-title ">
                        <span className="">Last Result</span>
                        <span className="">
                          <a className="">View All</a>
                        </span>
                      </div>
                      <div className="casino-last-results  ng-star-inserted">
                      {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                          (item) => (
                            <span
                              className={`ng-tns-c3502172659-5 result result-${
                                item?.mainWinner === "T" ? "b" : "a"
                              } ng-star-inserted`}
                            >
                              <span className="ng-tns-c3502172659-5">
                                {" "}
                                {item?.mainWinner}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <PlacedBet casinoBets={casinoBets} />
              )
              }
             
            </div>
          </div>
         
        </div>
      </div>
      <PlaceBet GAME_ID={GAME_ID} />
    </app-casino-match-detail>
  );
}
