import React from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { CARD_ARRAY, getOddForEntity } from "./CasinoGeneric";
export default function AndarBahar2() {
  const GAME_ID = "ABJ";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  return (
    <app-casino-match-detail
      _nghost-ng-c3502172659
      className="ng-tns-c3502172659-4 ng-star-inserted"
    >
      <div className="main-container ng-tns-c3502172659-4">
        <div className="center-main-container casino-page ng-tns-c3502172659-4 ng-star-inserted">
          <div className="center-container ng-tns-c3502172659-4">
            <div className="casino-page-container ng-tns-c3502172659-4 ab2">
              <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-4">
                <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-4">
                  <li className="nav-item ng-tns-c3502172659-4">
                    <div className="ng-tns-c3502172659-4 active nav-link">
                      Game
                    </div>
                  </li>
                  <li className="nav-item ng-tns-c3502172659-4">
                    <div className="ng-tns-c3502172659-4 nav-link">
                      {" "}
                      Placed Bet (0){" "}
                    </div>
                  </li>
                </ul>
                <div className="pe-2 ng-tns-c3502172659-4">
                  <span className="casino-rid ng-tns-c3502172659-4">
                    <a className="d-block ng-tns-c3502172659-4">
                      <small className="ng-tns-c3502172659-4">Rules</small>
                    </a>{" "}
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-4 ng-star-inserted">
                      38.240907164057
                    </span>
                  </span>
                </div>
              </div>
              <div className="casino-header ng-tns-c3502172659-4">
                <span className="casino-name ng-tns-c3502172659-4">
                  {" "}
                  Andar Bahar 2
                  <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-4">
                    <small className="ng-tns-c3502172659-4">Rules</small>
                  </a>
                </span>
                <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-4">
                  <small className="ng-tns-c3502172659-4">
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-4 ng-star-inserted">
                      38.240907164057
                    </span>
                  </small>
                  <span className="ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    | Min: 100 | Max: 100000
                  </span>
                </span>
              </div>

              <div className="ng-tns-c3502172659-4 ng-star-inserted">
                <div className="casino-video ng-tns-c3502172659-4">
                  <div className="video-box-container ng-tns-c3502172659-4">
                    <div className="casino-video-box ng-tns-c3502172659-4 ng-star-inserted">
                      <iframe
                        className="ng-tns-c3502172659-4"
                        src="https://alpha-n.qnsports.live/route/?id=30430"
                      />
                    </div>
                  </div>
                  <div className="ng-tns-c3502172659-4 ng-star-inserted">
                    <div className="casino-video-cards ng-tns-c3502172659-4 ng-star-inserted">
                      <div className="ab-cards-container ng-tns-c3502172659-4">
                        <div className="row row5 align-items-center ng-tns-c3502172659-4">
                          <div className="col-1 ng-tns-c3502172659-4">
                            <div className="row row5 ng-tns-c3502172659-4">
                              <div className="col-12 ng-tns-c3502172659-4">
                                <b className="ng-tns-c3502172659-4">A</b>
                              </div>
                            </div>
                            <div className="row row5 ng-tns-c3502172659-4">
                              <div className="col-12 ng-tns-c3502172659-4">
                                <b className="ng-tns-c3502172659-4">B</b>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 ng-tns-c3502172659-4">
                            <div className="flip-card ng-tns-c3502172659-4">
                              <div className="flip-card-inner ng-tns-c3502172659-4">
                                <div className="flip-card-front ng-tns-c3502172659-4">
                                  <img
                                    className="ng-tns-c3502172659-4"
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2SS.webp"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="row row5 mb-1 ng-tns-c3502172659-4">
                              <div className="col-lg-2 col-3 ng-tns-c3502172659-4">
                                <div className="flip-card ng-tns-c3502172659-4">
                                  <div className="flip-card-front ng-tns-c3502172659-4">
                                    <img
                                      onerror="this.src='assets/images/cards/1.webp'"
                                      className="ng-tns-c3502172659-4"
                                      src="assets/images/cards/1.webp"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-10 col-9 ng-tns-c3502172659-4">
                                <div className="casino-result-cards col-lg-12 ng-tns-c3502172659-4">
                                  <div className="casino-result-cardimg ng-tns-c3502172659-4">
                                    <div className="andar-bhar2-cards-slider ng-tns-c3502172659-4">
                                      <div className="draggable ng-tns-c3502172659-4" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row row5 ng-tns-c3502172659-4">
                              <div className="col-lg-2 col-3 ng-tns-c3502172659-4">
                                <div className="flip-card ng-tns-c3502172659-4">
                                  <div className="flip-card-inner ng-tns-c3502172659-4">
                                    <div className="flip-card-front ng-tns-c3502172659-4">
                                      <img
                                        onerror="this.src='assets/images/cards/1.webp'"
                                        className="ng-tns-c3502172659-4"
                                        src="assets/images/cards/1.webp"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-10 col-9 ng-tns-c3502172659-4">
                                <div className="casino-result-cards col-lg-12 ng-tns-c3502172659-4">
                                  <div className="casino-result-cardimg ng-tns-c3502172659-4">
                                    <div className="andar-bhar2-cards-slider ng-tns-c3502172659-4">
                                      <div className="draggable ng-tns-c3502172659-4" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="clock ng-tns-c3502172659-4">
                    <div className="count-no ng-tns-c3502172659-4 ng-star-inserted">
                      <span className="ng-tns-c3502172659-4">
                        <div className="content ng-tns-c3502172659-4">
                          <div
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-4"
                          >
                            <div className="flip ng-tns-c3502172659-4">
                              <div className="up ng-tns-c3502172659-4">
                                <div className="ng-tns-c3502172659-4">4</div>
                              </div>
                              <div className="down ng-tns-c3502172659-4">
                                <div className="ng-tns-c3502172659-4">
                                  <div className="ng-tns-c3502172659-4">4</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-4"
                          >
                            <div className="flip-card ng-tns-c3502172659-4">
                              <div className="flip-card-inner ng-tns-c3502172659-4 ng-trigger ng-trigger-flip ng-animating">
                                <div className="flip-card-front ng-tns-c3502172659-4">
                                  <div className="up ng-tns-c3502172659-4">
                                    <div className="ng-tns-c3502172659-4">
                                      4
                                    </div>
                                  </div>
                                </div>
                                <div className="flip-card-back ng-tns-c3502172659-4">
                                  <div className="down ng-tns-c3502172659-4">
                                    <div className="ng-tns-c3502172659-4">
                                      <div className="ng-tns-c3502172659-4">
                                        4
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div
                      className="count-no ng-tns-c3502172659-4 ng-star-inserted"
                      style={{ marginRight: "80px" }}
                    >
                      <span className="ng-tns-c3502172659-4">
                        <div className="content ng-tns-c3502172659-4">
                          <div
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-4"
                          >
                            <div className="flip ng-tns-c3502172659-4">
                              <div className="up ng-tns-c3502172659-4">
                                <div className="ng-tns-c3502172659-4">1</div>
                              </div>
                              <div className="down ng-tns-c3502172659-4">
                                <div className="ng-tns-c3502172659-4">
                                  <div className="ng-tns-c3502172659-4">1</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-4"
                          >
                            <div className="flip-card ng-tns-c3502172659-4">
                              <div className="flip-card-inner ng-tns-c3502172659-4 ng-trigger ng-trigger-flip">
                                <div className="flip-card-front ng-tns-c3502172659-4">
                                  <div className="up ng-tns-c3502172659-4">
                                    <div className="ng-tns-c3502172659-4">
                                      1
                                    </div>
                                  </div>
                                </div>
                                <div className="flip-card-back ng-tns-c3502172659-4">
                                  <div className="down ng-tns-c3502172659-4">
                                    <div className="ng-tns-c3502172659-4">
                                      <div className="ng-tns-c3502172659-4">
                                        1
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="casino-detail ng-tns-c3502172659-4">
                  <div className="casino-detail ng-tns-c3502172659-4 ng-star-inserted">
                    <div className="casino-table ng-tns-c3502172659-4">
                      <div className="casino-table-full-box ng-tns-c3502172659-4">
                        <div className="playera-bets ng-tns-c3502172659-4">
                          <div className="playera-title ng-tns-c3502172659-4">
                            A
                          </div>
                          <div className="player-sa ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="player-sa-box ng-tns-c3502172659-4">
                              <span className="ng-tns-c3502172659-4">
                                <div className="casino-odds ng-tns-c3502172659-4">
                                  {" "}
                                  SA{" "}
                                </div>
                                <div className="casino-volume ng-tns-c3502172659-4">
                                  {getOddForEntity("SA", casinoData, true)}
                                </div>
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>

                          <div className="player-bet ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="player-bet-box ng-tns-c3502172659-4">
                              <span className="ng-tns-c3502172659-4">
                                <div className="casino-odds ng-tns-c3502172659-4">
                                  {" "}
                                  1st Bet A{" "}
                                </div>
                                <div className="casino-volume ng-tns-c3502172659-4">
                                  {
                                    casinoData?.data?.response
                                      ?.oddDetailsDTOS[1]?.back1
                                  }
                                </div>
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>

                          <div className="player-bet ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="player-bet-box ng-tns-c3502172659-4 suspended-box">
                              <span className="ng-tns-c3502172659-4">
                                <div className="casino-odds ng-tns-c3502172659-4">
                                  {" "}
                                  2nd Bet A{" "}
                                </div>
                                <div className="casino-volume ng-tns-c3502172659-4">
                                  {
                                    casinoData?.data?.response
                                      ?.oddDetailsDTOS[2]?.back1
                                  }
                                </div>
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>

                          <div className="playera-title ng-tns-c3502172659-4">
                            A
                          </div>
                        </div>
                        <div className="playera-bets ng-tns-c3502172659-4">
                          <div className="playera-title ng-tns-c3502172659-4">
                            B
                          </div>
                          <div className="player-sa ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="player-sa-box ng-tns-c3502172659-4">
                              <span className="ng-tns-c3502172659-4">
                                <div className="casino-odds ng-tns-c3502172659-4">
                                  {" "}
                                  SB{" "}
                                </div>
                                <div className="casino-volume ng-tns-c3502172659-4">
                                  {getOddForEntity("sb", casinoData)}
                                </div>
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>

                          <div className="player-bet ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="player-bet-box ng-tns-c3502172659-4">
                              <span className="ng-tns-c3502172659-4">
                                <div className="casino-odds ng-tns-c3502172659-4">
                                  {" "}
                                  1st Bet B{" "}
                                </div>
                                <div className="casino-volume ng-tns-c3502172659-4">
                                  {
                                    casinoData?.data?.response
                                      ?.oddDetailsDTOS[4]?.back1
                                  }
                                </div>
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>

                          <div className="player-bet ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="player-bet-box ng-tns-c3502172659-4 suspended-box">
                              <span className="ng-tns-c3502172659-4">
                                <div className="casino-odds ng-tns-c3502172659-4">
                                  {" "}
                                  2nd Bet B{" "}
                                </div>
                                <div className="casino-volume ng-tns-c3502172659-4">
                                  {
                                    casinoData?.data?.response
                                      ?.oddDetailsDTOS[5]?.back1
                                  }
                                </div>
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>

                          <div className="playera-title ng-tns-c3502172659-4">
                            B
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-box mt-3 ng-tns-c3502172659-4">
                        <div className="casino-table-left-box ng-tns-c3502172659-4">
                          <div className="ab2-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="casino-odds text-center ng-tns-c3502172659-4 ng-star-inserted">
                              {" "}
                              ODD{" "}
                            </div>

                            <div className="casino-odds-box back ng-tns-c3502172659-4 suspended-box">
                              <span className="casino-odds ng-tns-c3502172659-4">
                                {getOddForEntity("joker odd", casinoData)}
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="ab2-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="casino-odds text-center ng-tns-c3502172659-4 ng-star-inserted">
                              {" "}
                              EVEN{" "}
                            </div>

                            <div className="casino-odds-box back ng-tns-c3502172659-4 suspended-box">
                              <span className="casino-odds ng-tns-c3502172659-4">
                                {getOddForEntity("joker even", casinoData)}
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-right-box ng-tns-c3502172659-4">
                          <div className="ab2-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="casino-odds text-center ng-tns-c3502172659-4">
                              <img
                                className="ng-tns-c3502172659-4 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                              />
                            </div>
                            <div className="casino-odds-box back ng-tns-c3502172659-4 suspended-box">
                              <span className="casino-odds ng-tns-c3502172659-4">
                                {getOddForEntity("joker spade", casinoData)}
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="ab2-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="casino-odds text-center ng-tns-c3502172659-4">
                              <img
                                className="ng-tns-c3502172659-4 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                              />
                            </div>
                            <div className="casino-odds-box back ng-tns-c3502172659-4 suspended-box">
                              <span className="casino-odds ng-tns-c3502172659-4">
                                {getOddForEntity("joker club", casinoData)}
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="ab2-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="casino-odds text-center ng-tns-c3502172659-4">
                              <img
                                className="ng-tns-c3502172659-4 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                              />
                            </div>
                            <div className="casino-odds-box back ng-tns-c3502172659-4 suspended-box">
                              <span className="casino-odds ng-tns-c3502172659-4">
                                {getOddForEntity("joker heart", casinoData)}
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div className="ab2-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="casino-odds text-center ng-tns-c3502172659-4">
                              <img
                                className="ng-tns-c3502172659-4 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                              />
                            </div>
                            <div className="casino-odds-box back ng-tns-c3502172659-4 suspended-box">
                              <span className="casino-odds ng-tns-c3502172659-4">
                                {getOddForEntity("joker diamond", casinoData)}
                              </span>
                            </div>
                            <div className="casino-nation-book text-center ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-full-box ab2cards mt-3 ng-tns-c3502172659-4">
                        <div className="text-center w-100 ng-tns-c3502172659-4">
                          <div className="casino-bl-box casino-cards-odds-title ng-tns-c3502172659-4">
                            <div className="casino-bl-box-item ng-tns-c3502172659-4">
                              <b className="ng-tns-c3502172659-4"> 12 </b>
                            </div>
                          </div>
                        </div>
                        {CARD_ARRAY?.map((item) => (
                          <div className="card-odd-box ng-tns-c3502172659-4 ng-star-inserted">
                            <div className="ng-tns-c3502172659-4 suspended-box">
                              <img
                                className="ng-tns-c3502172659-4 ng-star-inserted"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                              />
                            </div>
                            <div className="casino-nation-book ng-tns-c3502172659-4">
                              {" "}
                              0{" "}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="ng-tns-c3502172659-4">
                    <div className="casino-last-result-title ng-tns-c3502172659-4">
                      <span className="ng-tns-c3502172659-4">Last Result</span>
                      <span className="ng-tns-c3502172659-4">
                        <a className="ng-tns-c3502172659-4">View All</a>
                      </span>
                    </div>
                    <div className="casino-last-results ng-tns-c3502172659-4 ng-star-inserted">
                      <span className="ng-tns-c3502172659-4 result result-b ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> B</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-a ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> A</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-b ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> B</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-a ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> A</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-a ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> A</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-a ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> A</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-b ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> B</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-a ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> A</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-b ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> B</span>
                      </span>
                      <span className="ng-tns-c3502172659-4 result result-a ng-star-inserted">
                        <span className="ng-tns-c3502172659-4"> A</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ng-tns-c3502172659-4 d-none matchbettable w-100 ng-star-inserted">
                <div className="table-responsive ng-tns-c3502172659-4">
                  <table className="table ng-tns-c3502172659-4">
                    <thead className="ng-tns-c3502172659-4">
                      <tr className="ng-tns-c3502172659-4">
                        <th className="ng-tns-c3502172659-4">Matched Bet</th>
                        <th className="text-end ng-tns-c3502172659-4">Odds</th>
                        <th className="text-end ng-tns-c3502172659-4">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar right-sidebar casino-right-sidebar ng-tns-c3502172659-4">
            <div className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c3502172659-4">
              <div className="sidebar-title ng-tns-c3502172659-4">
                <h4 className="ng-tns-c3502172659-4">Place Bet</h4>
              </div>
            </div>
            <div className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c3502172659-4">
              <div className="sidebar-title ng-tns-c3502172659-4">
                <h4 className="ng-tns-c3502172659-4">My Bet</h4>
                <a className="sideviewmore ng-tns-c3502172659-4">View more</a>
              </div>
              <div className="my-bets ng-tns-c3502172659-4">
                <div className="table-responsive w-100 ng-tns-c3502172659-4">
                  <table className="table ng-tns-c3502172659-4">
                    <thead className="ng-tns-c3502172659-4">
                      <tr className="ng-tns-c3502172659-4">
                        <th className="ng-tns-c3502172659-4">Matched Bet</th>
                        <th className="text-end ng-tns-c3502172659-4">Odds</th>
                        <th className="text-end ng-tns-c3502172659-4">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c3502172659-4">
              <app-casino-game-rules
                className="ng-tns-c3502172659-4"
                _nghost-ng-c1761710207
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="placebetmodal"
        tabIndex={-1}
        aria-labelledby="placebetmodalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-4"
      >
        <div className="modal-dialog ng-tns-c3502172659-4">
          <div className="modal-content ng-tns-c3502172659-4">
            <div className="modal-header ng-tns-c3502172659-4">
              <h5
                id="placebetmodalLabel"
                className="modal-title ng-tns-c3502172659-4"
              >
                Place Bet
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close ng-tns-c3502172659-4"
              />
            </div>
            <div className="modal-body ng-tns-c3502172659-4">
              <div className="place-bet-modal ng-tns-c3502172659-4 lay">
                <div className="row ng-tns-c3502172659-4">
                  <div className="col-6 ng-tns-c3502172659-4">
                    <b className="ng-tns-c3502172659-4" />
                  </div>
                  <div className="col-6 ng-tns-c3502172659-4">
                    <div className="float-end ng-tns-c3502172659-4">
                      <button className="stakeactionminus btn ng-tns-c3502172659-4">
                        <span className="fa fa-minus ng-tns-c3502172659-4" />
                      </button>
                      <input
                        type="text"
                        className="stakeinput ng-tns-c3502172659-4 ng-untouched ng-pristine"
                        disabled
                      />
                      <button className="stakeactionminus btn ng-tns-c3502172659-4">
                        <span className="fa fa-plus ng-tns-c3502172659-4" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 ng-tns-c3502172659-4">
                  <div className="col-4 ng-tns-c3502172659-4">
                    <input
                      type="number"
                      className="stakeinput w-100 ng-tns-c3502172659-4 ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                  <div className="col-4 d-grid ps-0 ng-tns-c3502172659-4">
                    <button className="btn btn-primary btn-block btn-loader ng-tns-c3502172659-4">
                      Submit{" "}
                    </button>
                  </div>
                  <div className="col-4 text-center pt-2 ng-tns-c3502172659-4" />
                </div>
                <div className="place-bet-buttons mt-2 ng-tns-c3502172659-4">
                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    100{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    200{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    500{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    1000{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    10000{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    20000{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    50000{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    100000{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    200000{" "}
                  </button>

                  <button className="btn btn-place-bet ng-tns-c3502172659-4 ng-star-inserted">
                    {" "}
                    500000{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="rulesmodal"
        tabIndex={-1}
        aria-labelledby="teenpattidLabel"
        aria-hidden="true"
        className="modal fade rules-modal ng-tns-c3502172659-4"
      >
        <div className="modal-dialog ng-tns-c3502172659-4">
          <div className="modal-content ng-tns-c3502172659-4">
            <div className="modal-header ng-tns-c3502172659-4">
              <h5
                id="teenpattidLabel"
                className="modal-title ng-tns-c3502172659-4"
              >
                Rules
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close ng-tns-c3502172659-4"
              />
            </div>
            <app-casino-rules-modal
              className="ng-tns-c3502172659-4"
              _nghost-ng-c313361437
            >
              <div _ngcontent-ng-c313361437 className="modal-body p-0">
                <img
                  _ngcontent-ng-c313361437
                  observevisibility
                  className="img-fluid ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/casino-rules/ab2-rules.webp"
                />
              </div>
            </app-casino-rules-modal>
          </div>
        </div>
      </div>
      <div
        id="Books"
        tabIndex={-1}
        aria-labelledby="MatchOddsModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-4"
      >
        <div className="modal-dialog match-details-rules ng-tns-c3502172659-4">
          <div className="modal-content ng-tns-c3502172659-4">
            <div className="modal-header ng-tns-c3502172659-4">
              <h5
                id="ResultModalLabel"
                className="modal-title ng-tns-c3502172659-4"
              >
                Books
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close ng-tns-c3502172659-4"
              />
            </div>
            <div className="modal-body ng-tns-c3502172659-4">
              <div className="table-responsive ng-tns-c3502172659-4">
                <table className="table table-bordered ng-tns-c3502172659-4">
                  <thead className="ng-tns-c3502172659-4">
                    <tr className="ng-tns-c3502172659-4">
                      <th className="ng-tns-c3502172659-4">Run</th>
                      <th className="ng-tns-c3502172659-4">Amount</th>
                    </tr>
                  </thead>
                  <tbody className="ng-tns-c3502172659-4">
                    <tr className="ng-tns-c3502172659-4 ng-star-inserted">
                      <td
                        colSpan={2}
                        className="text-center ng-tns-c3502172659-4"
                      >
                        No Record found !
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="BookMarkerModal"
        tabIndex={-1}
        aria-labelledby="BookMarkerModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-4"
      >
        <div className="modal-dialog match-details-rules ng-tns-c3502172659-4">
          <div className="modal-content ng-tns-c3502172659-4">
            <app-bookmarker
              className="ng-tns-c3502172659-4"
              _nghost-ng-c3168318478
            >
              <div _ngcontent-ng-c3168318478 className="modal-header">
                <h5
                  _ngcontent-ng-c3168318478
                  id="ResultModalLabel"
                  className="modal-title"
                >
                  Rules
                </h5>
                <button
                  _ngcontent-ng-c3168318478
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c3168318478 className="modal-body p-3">
                <div _ngcontent-ng-c3168318478 className="card-header mb-3">
                  <div _ngcontent-ng-c3168318478 className="card-title">
                    {" "}
                    BookMarker{" "}
                  </div>
                </div>
                <div _ngcontent-ng-c3168318478 className="rules-table-design">
                  <table
                    _ngcontent-ng-c3168318478
                    className="table table-bordered w-100"
                  >
                    <tbody _ngcontent-ng-c3168318478>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            1. Even odd game betting rate rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.1 Completed game is valid , in case due to rain
                            over are reduced or match abandoned particular game
                            will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.2 All bets regarding to ODD/EVEN
                            player/partnership are valid if one legal delivery
                            is being played, else the bets will be deleted.
                            Player odd/even all advance bets will be valid if
                            one legal delivery is being played in match
                            otherwise voided.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.3 All bets regarding Odd/Even sessions will be
                            deleted if the particular session is incomplete, for
                            example match got abandoned or finished before the
                            end of particular session.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.4 In any circumstances management decision will be
                            final.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            2 Top batsman rules:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            2.1 If any player does not come as per playing
                            eleven then all bets will be get deleted for the
                            particular player.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2.2 two players done the same run in a single match
                            (M Agarwal 30 runs and A Rayudu 30 runs, whole
                            inning top batsmen score also 30 run) then both
                            player settlement to be get done 50 percent (50% ,
                            50%)rate on their original value which given by our
                            exchange.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Suppose we have opened value of M Agarwal 3.75 back
                            and customer place bets on 10000 @ 3.75 rates and A
                            Rayudu 3.0 back and customer place bets on 10000 @
                            3.0 rates.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Whole inning result announces 30 run by both player
                            then
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on M Agarwal you
                            will be get half amount of this rate
                            (10000*3.75/2=18750 you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on A Rayudu you will
                            be get half amount of this rate (10000*3.00/2=15000
                            you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Top batsman only 1st inning valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            For one day 50 over and for t-20 match 20 over must
                            be played for top batsmen otherwise all bets will be
                            deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Man of the Match Rules
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. All bets will be deleted in case the match is
                            abandoned or over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. All bets will be deleted if the mentioned player
                            is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. In case Man of the Match is shared between two
                            players then Dead heat rule will be applicable, For
                            example K Perera and T Iqbal shares the Man of the
                            Match, then the settlement will be done 50% of the
                            rates accordingly.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            4. Rules similar to our Top Batsman rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Maximum Sixes by Team
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. All bets will be deleted if match abandoned or
                            over reduced
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. All bets will be deleted if both the teams hits
                            same number of sixes.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. Super over will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Maximum 6 or 10 over runs
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. All bets will be deleted if match abandoned or
                            over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. All the bets will be deleted if both the teams
                            score is same (Runs scored in 6 or 10 overs)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. 6 overs for T20 and 10 overs for ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            4. Both the innings are valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            5. This fancy will be valid for 1st 6 overs of both
                            innings for T20 and 1st 10 overs of both innings for
                            ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Batsman Match
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Batsman Match:- Bets for Favourite batsman from the
                            two batsman matched.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if any one of the mentioned
                            player is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted unless one ball being
                            played by both the mentioned players.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if over reduced or Match
                            abandoned.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if both the player scored
                            same run. For example H Amla and J Bairstow are the
                            batsman matched, H Amla and J Bairstow both scored
                            38 runs then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Opening Pair
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. Bets for Favourite opening pair from the two
                            mentioned opening pair.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. Runs made by both the opening player will be
                            added. For example:- J Roy scored 20 runs and J
                            Bairstow scored 30 runs result will be 50 runs.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. Highest run made by the pair will be declared as
                            winner. For example: Opening pair ENG total is 70
                            runs and Opening pair SA is 90 runs, then SA 90 runs
                            will be declared as winner.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Our exchange Special
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if the mentioned player is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Direction of First Boundary
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            The boundary hit through off side of the stump will
                            be considered as off side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            The boundary hit through leg side of the stump will
                            be considered as leg side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered as
                            valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Only 1st Inning will be considered
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Fifty &amp; Century by Batsman
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted unless the batsman faces
                            one legal ball.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both Innings will be valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            1st over Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Only 1st inning will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Odd Even Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Incompleted games will be deleted. Over reduced or
                            abandoned all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            For example:-275 run SL bhav must be played 50 over
                            if rain comes or any condition otherwise 275 run SL
                            bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Next Man out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Next man out fancy advance &amp; in regular. Both
                            inning will be valid. If any player does not come in
                            opening then all bets will be deleted. If over
                            reduced or abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Caught out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Caught out fancy in advance &amp; in regular. Both
                            inning will be valid. If over reduced or match
                            abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Wkt &amp; All out Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            5 wkt in 10 over &amp; All out in 20 over fancy is
                            valid for both inning. If match abandoned or over
                            reduced all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Test Match: Game Completed Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. This is the fancy for match to be won/ completed
                            in which day &amp; session (Completed: Game has to
                            be completed)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. If match drawn then all the sessions will be
                            considered as lost.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Meter Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            In case match abandoned or over reduced mid point
                            rule will be applicable
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            For example: If Dhoni meter is 75 / 77 and the match
                            abandoned or over reduced, then the result will be
                            76
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            In case of single difference result will be given
                            for the higher rate of the final rate (eg 53/54) and
                            match gets abandoned then the result will be given
                            as 54
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Midpoint rule is applicable for test match also.
                            However for lambi meter/ inning meter 70 overs has
                            to be played only then the same will be considered
                            as valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Maximum Boundaries:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            If the number of fours or sixes of both the team is
                            equal, then all bets of the respective event will
                            get voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Khado:- Test
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Minimum 70 over has to be played by the particular
                            team only then the Khado of the team will be
                            considered as valid, else the same will be voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Common Market:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            In future, if any circumstances happens like covid
                            issue , natural disasters or any reasons series will
                            postponed or cancelled then at that moment result
                            will be given to difference of opening rate to
                            present rate.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Due to any reasons company has rights to take final
                            decisions.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Common Means Only 1 Win
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-bookmarker>
          </div>
        </div>
      </div>
      <div
        id="MatchOddsModal"
        tabIndex={-1}
        aria-labelledby="MatchOddsModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-4"
      >
        <div className="modal-dialog match-details-rules ng-tns-c3502172659-4">
          <div className="modal-content ng-tns-c3502172659-4">
            <app-match-odds
              className="ng-tns-c3502172659-4"
              _nghost-ng-c3254903801
            >
              <div className="modal-header">
                <h5 id="ResultModalLabel" className="modal-title">
                  Rules
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div className="modal-body p-3">
                <div className="card-header mb-3">
                  <div className="card-title"> MATCH ODDS </div>
                </div>
                <div className="rules-table-design">
                  <table className="table table-bordered w-100">
                    <tbody>
                      <tr>
                        <td>
                          <span className="text-danger">
                            1. Even odd game betting rate rules.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.1 Completed game is valid , in case due to rain
                            over are reduced or match abandoned particular game
                            will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.2 All bets regarding to ODD/EVEN
                            player/partnership are valid if one legal delivery
                            is being played, else the bets will be deleted.
                            Player odd/even all advance bets will be valid if
                            one legal delivery is being played in match
                            otherwise voided.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.3 All bets regarding Odd/Even sessions will be
                            deleted if the particular session is incomplete, for
                            example match got abandoned or finished before the
                            end of particular session.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.4 In any circumstances management decision will be
                            final.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            2 Top batsman rules:-
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            2.1 If any player does not come as per playing
                            eleven then all bets will be get deleted for the
                            particular player.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2.2 two players done the same run in a single match
                            (M Agarwal 30 runs and A Rayudu 30 runs, whole
                            inning top batsmen score also 30 run) then both
                            player settlement to be get done 50 percent (50% ,
                            50%)rate on their original value which given by our
                            exchange.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Suppose we have opened value of M Agarwal 3.75 back
                            and customer place bets on 10000 @ 3.75 rates and A
                            Rayudu 3.0 back and customer place bets on 10000 @
                            3.0 rates.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Whole inning result announces 30 run by both player
                            then
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Rule of top batsman:-if you bet on M Agarwal you
                            will be get half amount of this rate
                            (10000*3.75/2=18750 you will get)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Rule of top batsman:-if you bet on A Rayudu you will
                            be get half amount of this rate (10000*3.00/2=15000
                            you will get)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Top batsman only 1st inning valid.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            For one day 50 over and for t-20 match 20 over must
                            be played for top batsmen otherwise all bets will be
                            deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Man of the Match Rules
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. All bets will be deleted in case the match is
                            abandoned or over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. All bets will be deleted if the mentioned player
                            is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            3. In case Man of the Match is shared between two
                            players then Dead heat rule will be applicable, For
                            example K Perera and T Iqbal shares the Man of the
                            Match, then the settlement will be done 50% of the
                            rates accordingly.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            4. Rules similar to our Top Batsman rules.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Maximum Sixes by Team
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. All bets will be deleted if match abandoned or
                            over reduced
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. All bets will be deleted if both the teams hits
                            same number of sixes.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>3. Super over will not be considered.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Maximum 6 or 10 over runs
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. All bets will be deleted if match abandoned or
                            over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. All the bets will be deleted if both the teams
                            score is same (Runs scored in 6 or 10 overs)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>3. 6 overs for T20 and 10 overs for ODI</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>4. Both the innings are valid.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            5. This fancy will be valid for 1st 6 overs of both
                            innings for T20 and 1st 10 overs of both innings for
                            ODI
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Batsman Match</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Batsman Match:- Bets for Favourite batsman from the
                            two batsman matched.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if any one of the mentioned
                            player is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted unless one ball being
                            played by both the mentioned players.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if over reduced or Match
                            abandoned.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if both the player scored
                            same run. For example H Amla and J Bairstow are the
                            batsman matched, H Amla and J Bairstow both scored
                            38 runs then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both innings will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Opening Pair</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. Bets for Favourite opening pair from the two
                            mentioned opening pair.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. Runs made by both the opening player will be
                            added. For example:- J Roy scored 20 runs and J
                            Bairstow scored 30 runs result will be 50 runs.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            3. Highest run made by the pair will be declared as
                            winner. For example: Opening pair ENG total is 70
                            runs and Opening pair SA is 90 runs, then SA 90 runs
                            will be declared as winner.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both innings will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Our exchange Special
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if the mentioned player is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both innings will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Direction of First Boundary
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            The boundary hit through off side of the stump will
                            be considered as off side four.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            The boundary hit through leg side of the stump will
                            be considered as leg side four.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered as
                            valid.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Only 1st Inning will be considered</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Fifty &amp; Century by Batsman
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted unless the batsman faces
                            one legal ball.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both Innings will be valid.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">1st over Fancy</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Only 1st inning will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Odd Even Fancy</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Incompleted games will be deleted. Over reduced or
                            abandoned all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            For example:-275 run SL bhav must be played 50 over
                            if rain comes or any condition otherwise 275 run SL
                            bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Next Man out</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Next man out fancy advance &amp; in regular. Both
                            inning will be valid. If any player does not come in
                            opening then all bets will be deleted. If over
                            reduced or abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Caught out</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Caught out fancy in advance &amp; in regular. Both
                            inning will be valid. If over reduced or match
                            abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Wkt &amp; All out Fancy
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            5 wkt in 10 over &amp; All out in 20 over fancy is
                            valid for both inning. If match abandoned or over
                            reduced all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Test Match: Game Completed Fancy
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. This is the fancy for match to be won/ completed
                            in which day &amp; session (Completed: Game has to
                            be completed)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. If match drawn then all the sessions will be
                            considered as lost.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Meter Fancy</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            In case match abandoned or over reduced mid point
                            rule will be applicable
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            For example: If Dhoni meter is 75 / 77 and the match
                            abandoned or over reduced, then the result will be
                            76
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            In case of single difference result will be given
                            for the higher rate of the final rate (eg 53/54) and
                            match gets abandoned then the result will be given
                            as 54
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Midpoint rule is applicable for test match also.
                            However for lambi meter/ inning meter 70 overs has
                            to be played only then the same will be considered
                            as valid
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Maximum Boundaries:-
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            If the number of fours or sixes of both the team is
                            equal, then all bets of the respective event will
                            get voided
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Khado:- Test</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Minimum 70 over has to be played by the particular
                            team only then the Khado of the team will be
                            considered as valid, else the same will be voided
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Common Market:-</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            In future, if any circumstances happens like covid
                            issue , natural disasters or any reasons series will
                            postponed or cancelled then at that moment result
                            will be given to difference of opening rate to
                            present rate.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Due to any reasons company has rights to take final
                            decisions.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Common Means Only 1 Win</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-match-odds>
          </div>
        </div>
      </div>
      <div
        id="FancyModal"
        tabIndex={-1}
        aria-labelledby="FancyModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-4"
      >
        <div className="modal-dialog match-details-rules ng-tns-c3502172659-4">
          <div className="modal-content ng-tns-c3502172659-4">
            <app-fancy-rules
              className="ng-tns-c3502172659-4"
              _nghost-ng-c3779754666
            >
              <div className="modal-header">
                <h5 id="ResultModalLabel" className="modal-title">
                  Rules
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div className="modal-body p-3">
                <div className="card-header mb-3">
                  <div className="card-title"> SESSIONS </div>
                </div>
                <div className="rules-table-design">
                  <table className="table table-bordered w-100">
                    <tbody>
                      <tr>
                        <td>
                          <span className="text-danger">
                            1. Even odd game betting rate rules.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.1 Completed game is valid , in case due to rain
                            over are reduced or match abandoned particular game
                            will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.2 All bets regarding to ODD/EVEN
                            player/partnership are valid if one legal delivery
                            is being played, else the bets will be deleted.
                            Player odd/even all advance bets will be valid if
                            one legal delivery is being played in match
                            otherwise voided.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.3 All bets regarding Odd/Even sessions will be
                            deleted if the particular session is incomplete, for
                            example match got abandoned or finished before the
                            end of particular session.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1.4 In any circumstances management decision will be
                            final.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            2 Top batsman rules:-
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            2.1 If any player does not come as per playing
                            eleven then all bets will be get deleted for the
                            particular player.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2.2 two players done the same run in a single match
                            (M Agarwal 30 runs and A Rayudu 30 runs, whole
                            inning top batsmen score also 30 run) then both
                            player settlement to be get done 50 percent (50% ,
                            50%)rate on their original value which given by our
                            exchange.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Suppose we have opened value of M Agarwal 3.75 back
                            and customer place bets on 10000 @ 3.75 rates and A
                            Rayudu 3.0 back and customer place bets on 10000 @
                            3.0 rates.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Whole inning result announces 30 run by both player
                            then
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Rule of top batsman:-if you bet on M Agarwal you
                            will be get half amount of this rate
                            (10000*3.75/2=18750 you will get)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Rule of top batsman:-if you bet on A Rayudu you will
                            be get half amount of this rate (10000*3.00/2=15000
                            you will get)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Top batsman only 1st inning valid.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            For one day 50 over and for t-20 match 20 over must
                            be played for top batsmen otherwise all bets will be
                            deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Man of the Match Rules
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. All bets will be deleted in case the match is
                            abandoned or over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. All bets will be deleted if the mentioned player
                            is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            3. In case Man of the Match is shared between two
                            players then Dead heat rule will be applicable, For
                            example K Perera and T Iqbal shares the Man of the
                            Match, then the settlement will be done 50% of the
                            rates accordingly.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            4. Rules similar to our Top Batsman rules.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Maximum Sixes by Team
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. All bets will be deleted if match abandoned or
                            over reduced
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. All bets will be deleted if both the teams hits
                            same number of sixes.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>3. Super over will not be considered.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Maximum 6 or 10 over runs
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. All bets will be deleted if match abandoned or
                            over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. All the bets will be deleted if both the teams
                            score is same (Runs scored in 6 or 10 overs)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>3. 6 overs for T20 and 10 overs for ODI</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>4. Both the innings are valid.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            5. This fancy will be valid for 1st 6 overs of both
                            innings for T20 and 1st 10 overs of both innings for
                            ODI
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Batsman Match</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Batsman Match:- Bets for Favourite batsman from the
                            two batsman matched.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if any one of the mentioned
                            player is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted unless one ball being
                            played by both the mentioned players.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if over reduced or Match
                            abandoned.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if both the player scored
                            same run. For example H Amla and J Bairstow are the
                            batsman matched, H Amla and J Bairstow both scored
                            38 runs then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both innings will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Opening Pair</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. Bets for Favourite opening pair from the two
                            mentioned opening pair.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. Runs made by both the opening player will be
                            added. For example:- J Roy scored 20 runs and J
                            Bairstow scored 30 runs result will be 50 runs.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            3. Highest run made by the pair will be declared as
                            winner. For example: Opening pair ENG total is 70
                            runs and Opening pair SA is 90 runs, then SA 90 runs
                            will be declared as winner.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both innings will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Our exchange Special
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if the mentioned player is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both innings will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Direction of First Boundary
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            The boundary hit through off side of the stump will
                            be considered as off side four.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            The boundary hit through leg side of the stump will
                            be considered as leg side four.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered as
                            valid.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Only 1st Inning will be considered</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Fifty &amp; Century by Batsman
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            All bets will be deleted unless the batsman faces
                            one legal ball.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Both Innings will be valid.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">1st over Fancy</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Only 1st inning will be valid</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Odd Even Fancy</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Incompleted games will be deleted. Over reduced or
                            abandoned all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            For example:-275 run SL bhav must be played 50 over
                            if rain comes or any condition otherwise 275 run SL
                            bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Next Man out</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Next man out fancy advance &amp; in regular. Both
                            inning will be valid. If any player does not come in
                            opening then all bets will be deleted. If over
                            reduced or abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Caught out</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Caught out fancy in advance &amp; in regular. Both
                            inning will be valid. If over reduced or match
                            abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Wkt &amp; All out Fancy
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            5 wkt in 10 over &amp; All out in 20 over fancy is
                            valid for both inning. If match abandoned or over
                            reduced all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Test Match: Game Completed Fancy
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            1. This is the fancy for match to be won/ completed
                            in which day &amp; session (Completed: Game has to
                            be completed)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            2. If match drawn then all the sessions will be
                            considered as lost.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Meter Fancy</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            In case match abandoned or over reduced mid point
                            rule will be applicable
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            For example: If Dhoni meter is 75 / 77 and the match
                            abandoned or over reduced, then the result will be
                            76
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            In case of single difference result will be given
                            for the higher rate of the final rate (eg 53/54) and
                            match gets abandoned then the result will be given
                            as 54
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Midpoint rule is applicable for test match also.
                            However for lambi meter/ inning meter 70 overs has
                            to be played only then the same will be considered
                            as valid
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">
                            Maximum Boundaries:-
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            If the number of fours or sixes of both the team is
                            equal, then all bets of the respective event will
                            get voided
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Khado:- Test</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Minimum 70 over has to be played by the particular
                            team only then the Khado of the team will be
                            considered as valid, else the same will be voided
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-danger">Common Market:-</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            In future, if any circumstances happens like covid
                            issue , natural disasters or any reasons series will
                            postponed or cancelled then at that moment result
                            will be given to difference of opening rate to
                            present rate.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            Due to any reasons company has rights to take final
                            decisions.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Common Means Only 1 Win</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-fancy-rules>
          </div>
        </div>
      </div>
      <div
        id="LastResultModal"
        tabIndex={-1}
        aria-labelledby="LastResultModalLabel"
        aria-hidden="true"
        className="modal fade result-modal ng-tns-c3502172659-4"
      >
        <app-last-result-modal
          className="ng-tns-c3502172659-4"
          _nghost-ng-c88570608
        >
          <div _ngcontent-ng-c88570608 className="modal-dialog modal-lg">
            <div _ngcontent-ng-c88570608 className="modal-content">
              <div _ngcontent-ng-c88570608 className="modal-header">
                <h5
                  _ngcontent-ng-c88570608
                  id="LastResultModalLabel"
                  className="modal-title"
                />
                <button
                  _ngcontent-ng-c88570608
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div
                _ngcontent-ng-c88570608
                className="Baccarat2result modal-body"
              />
            </div>
          </div>
        </app-last-result-modal>
      </div>
    </app-casino-match-detail>
  );
}
