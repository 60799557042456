import React from "react";
import { Encrypt, PUBLIC_KEY } from "../../utils/constants";
import { JSEncrypt } from "jsencrypt";
import {
  disableMFAAPI,
  generateMFAAPI,
  resendCodeAPI,
  verifyCodeAPI,
} from "../../service/AuthService";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function SecurityAuth() {
  const [password, setPassword] = React.useState("");
  const [mfaCode, setMfaCode] = React.useState(null);
  const userData = useSelector((state) => state.account.userData);
  const [isOtpScreen, setIsOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState({
    otp1: { value: "", ref: React.createRef() },
    otp2: { value: "", ref: React.createRef() },
    otp3: { value: "", ref: React.createRef() },
    otp4: { value: "", ref: React.createRef() },
    otp5: { value: "", ref: React.createRef() },
    otp6: { value: "", ref: React.createRef() },
  });
  const handleOptChange = (e, key, index) => {
    if (e.target.value.length > 1) {
      return;
    }
    setOtp({
      ...otp,
      [key]: { value: e.target.value },
    });
    if (e.target.value.length > 0 && index < Object.keys(otp).length - 1) {
      otp[Object.keys(otp)[index + 1]].ref.current.focus();
    }
  };

  const handleSubmit = async () => {
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    const { response, code } = await generateMFAAPI(encryptedPassword);
    if (code == 200) {
      setMfaCode(response?.mfaCode);
      toast.success("MFA generated successfully");
    } else {
      toast.error(response);
    }
  };
  const disableMFA = async () => {
    const { response, code } = await disableMFAAPI();
    if (code == 200) {
      setIsOtpScreen(true);
    } else {
      toast.error(response);
    }
  };
  const handleMfaDisable = async () => {
    const { response, code } = await verifyCodeAPI(
      userData?.username,
      Object.values(otp)
        .map((otp) => otp.value)
        .join(""),
      true
    );
    if (code == 200) {
      toast.success("MFA disabled successfully");
      setIsOtpScreen(false);
    } else {
      toast.error(response);
    }
  };
  const isMobile = window.innerWidth < 786;
  const Otp = () => {
    return (
      <div
        style={{
          width: isMobile ? "100%" : "82%",
        }}
        className="report-container text-center mt-3 col-md-12"
      >
        <div
          style={{
            width: "100% !important",
          }}
          className="mt-2 mb-3"
        >
          <h3 className="text-center">
            Security Code Verification using Telegram
          </h3>{" "}
          <div className="mt-3 text-center">
            Enter 6-digit code received in{" "}
            <a
              target="_blank"
              href="https://t.me/bvmauthbot?start"
              className="text-primary"
            >
              @bvmauthbot
            </a>{" "}
            Bot
          </div>{" "}
          <div role="form" autoComplete="off" method="post" className="mt-3">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div is-input-num="true" style={{ display: "flex" }}>
                {Object.keys(otp).map((key, index) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      min={0}
                      max={9}
                      maxLength={1}
                      pattern="[0-9]"
                      type="tel"
                      onChange={(e) => handleOptChange(e, key, index)}
                      value={otp[key].value}
                      ref={otp[key].ref}
                      className="otp-input"
                    />
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={handleMfaDisable}
              className="btn btn-primary mt-3"
            >
              Submit
            </button>
          </div>
        </div>{" "}
      </div>
    );
  };
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="mb-0">Secure Auth Verification</h4>
      </div>{" "}
      <div className="card-body security-auth">
        <div className="row justify-content-center mt-5 mb-5">
          <div className="col-12">
            <div className="setting-box">
              <span className="mr-3">Secure Auth Verification Status:</span>{" "}
              <span
                onClick={() => {
                  if (userData?.mfaEnabled) {
                    disableMFA();
                  }
                }}
                style={{ cursor: "pointer" }}
                className={`badge badge-${
                  userData?.mfaEnabled ? "success" : "danger"
                }`}
              >
                {userData?.mfaEnabled ? "Enabled" : "Disabled"}
              </span>
            </div>{" "}
            {isOtpScreen && <Otp />}
            {!userData?.mfaEnabled && (
              <>
                <div className="mt-2 text-center">
                  <fieldset className="form-group" id="__BVID__150">
                    <legend
                      tabIndex={-1}
                      className="bv-no-focus-ring col-form-label pt-0"
                      id="__BVID__150__BV_label_"
                    >
                      Please select below option to enable secure auth
                      verification
                    </legend>
                    <div>
                      <div
                        id="radio-group-1"
                        role="radiogroup"
                        tabIndex={-1}
                        className="btn-group-toggle btn-group btn-group-lg bv-no-focus-ring"
                      >
                        {/*   <label className="btn btn-outline-success btn-lg">
                      <input
                        id="radio-group-1_BV_option_0"
                        type="radio"
                        name="auth-options"
                        className=""
                        defaultValue={1}
                      />
                      <span>Enable Using Mobile App</span>
                    </label> */}
                        <label className="btn btn-outline-success btn-lg active">
                          <input
                            id="radio-group-1_BV_option_1"
                            type="radio"
                            name="auth-options"
                            className=""
                            defaultValue={2}
                          />
                          <span>Enable Using Telegram</span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>{" "}
                <div className="text-center mt-3">
                  {" "}
                  <div>
                    <p className="mb-3">
                      Please enter your login password to continue
                    </p>{" "}
                    <form className="form-inline">
                      <label
                        htmlFor="inline-form-input-name"
                        className="sr-only"
                      >
                        Password
                      </label>{" "}
                      <input
                        id="inline-form-input-name"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Your Login Password"
                        className="mb-2 mr-sm-2 mb-sm-0 form-control"
                      />{" "}
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary"
                      >
                        Get Connection Id
                      </button>
                    </form>{" "}
                    {mfaCode && (
                      <div className="mt-3 small-box">
                        <h4>
                          Please follow below instructions for the telegram
                          2-Step veriication:
                        </h4>{" "}
                        <p>
                          Find{" "}
                          <a
                            target="_blank"
                            href="https://t.me/bvmauthbot?start"
                            className="text-primary"
                          >
                            @bvmauthbot
                          </a>{" "}
                          in your telegram and type <kbd>/start</kbd> command.
                          Bot will respond you.
                        </p>{" "}
                        <p className="text-dark">
                          After this type <kbd>/connect {mfaCode}</kbd> and send
                          it to BOT.
                        </p>{" "}
                        <p>
                          Now your telegram account will be linked with your
                          website account and 2-Step veriication will be
                          enabled.
                        </p>{" "}
                        <hr />{" "}
                        <div className="font-hindi mt-4">
                          <h4>
                            कृपया टेलीग्राम 2-Step verification के लिए नीचे दिए
                            गए निर्देशों का पालन करें:
                          </h4>{" "}
                          <p>
                            अपने टेलीग्राम में{" "}
                            <a
                              target="_blank"
                              href="https://t.me/bvmauthbot?start"
                              className="text-primary"
                            >
                              @bvmauthbot
                            </a>{" "}
                            खोजें और कमांड <kbd>/start</kbd> टाइप करें. BOT आपको
                            जवाब देगा.
                          </p>{" "}
                          <p className="text-dark">
                            इसके बाद <kbd>/connect {mfaCode}</kbd> टाइप करें और
                            इसे BOT पर भेजें.
                          </p>{" "}
                          <p>
                            अब आपका टेलीग्राम account आपके वेबसाइट account से
                            जुड़ जाएगा और 2-Step veriication चालू हो जाएगा.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
