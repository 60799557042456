import React from "react";
import "../index.scoped.css";
export default function PlacedBet({ bets, casino, setSelectedTab }) {
  return (
    <div className="ng-tns-c3502172659-3 matchbettable w-100 ng-star-inserted">
      <div className="table-responsive ng-tns-c3502172659-3">
        <table className="table ng-tns-c3502172659-3">
          <thead className="ng-tns-c3502172659-3">
            <tr className="ng-tns-c3502172659-3">
              <th className="ng-tns-c3502172659-3">Matched Bet</th>
              <th className="text-end ng-tns-c3502172659-3">Odds</th>
              <th className="text-end ng-tns-c3502172659-3">Stake</th>
            </tr>
          </thead>
          <tbody className="ng-tns-c3502172659-3">
            {bets?.map((item) => (
              <tr className="ng-tns-c3502172659-3">
                <td className="ng-tns-c3502172659-3">{item?.runnerName}</td>
                <td className="text-end ng-tns-c3502172659-3">
                  {item?.back ? item?.back : item?.lay}
                </td>
                <td className="text-end ng-tns-c3502172659-3">
                  {item?.amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
