import React from "react";
import "./index.scoped.css";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import PlaceBet from "./components/PlaceBet";
import CasinoVideo from "./components/CasinoVideo";
import { CASINO_VIDEO, setBet } from "./CasinoGeneric";
import Timer from "./components/Timer";
import PlacedBet from "./components/PlacedBet";
export default function FiveCricket() {
  const GAME_ID = "CRICKET_V3";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [selectedTab, setSelectedTab] = React.useState("game");
  return (
    <app-casino-match-detail
      _nghost-ng-c3502172659
      className=" ng-star-inserted"
    >
      <div className="main-container ">
        <div className="center-main-container casino-page  ng-star-inserted">
          <div className="center-container ">
            <div className="casino-page-container  super-over">
              <div className="casino-title-header-mobile d-xl-none ">
                <ul className="nav nav-tabs menu-tabs ">
                  <li
                    onClick={() => setSelectedTab("game")}
                    className="nav-item "
                  >
                    <div className=" active nav-link">Game</div>
                  </li>
                  <li
                    onClick={() => setSelectedTab("bet")}
                    className="nav-item "
                  >
                    <div className=" nav-link">
                      {" "}
                      Placed Bet ({casinoBets?.data?.response?.length}){" "}
                    </div>
                  </li>
                </ul>
                <div className="pe-2 ">
                  <span className="casino-rid ">
                    <a className="d-block ">
                      <small className="">Rules</small>
                    </a>
                    Round ID:
                    <span className=" ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </span>
                </div>
              </div>
              <div className="casino-header ">
                <span className="casino-name ">
                  5FIVE CRICKET
                  <a className="ms-1 d-xl-inline d-none ">
                    <small className="">Rules</small>
                  </a>
                </span>
                <span className="casino-rid d-none d-xl-inline-block ">
                  <small className="">
                    Round ID:
                    <span className=" ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </small>
                  <span className=" ng-star-inserted">
                    | Min:
                    <span className=" ng-star-inserted"> 100 </span>| Max:
                    <span className=" ng-star-inserted"> 300000 </span>
                  </span>
                </span>
              </div>

              <div className="scorecard m-b-5 d-lg-block d-none  ng-star-inserted">
                <div className="row ">
                  <div className="col-md-5 ">
                    <p className="team-1 row ">
                      <span className="team-name col-md-3 ">AUS</span>
                      <span className="score col-md-9 text-right ">
                        66-3 (5.0)
                      </span>
                    </p>
                    <p className="team-1 row m-t-10 ">
                      <span className="team-name col-md-3 ">IND</span>
                      <span className="score col-md-9 text-right ">
                        32-3 (2.2)
                      </span>
                    </p>
                  </div>
                  <div className="col-md-7 ">
                    <div className="row ">
                      <div className="col-md-5 ">
                        <div className="row ">
                          <span className="team-name ">&nbsp; </span>
                        </div>
                        <div className="row m-t-10 ">
                          <span className="team-name ">
                            <span className=" ng-star-inserted">
                              CRR 13.71
                              <span className=" ng-star-inserted">
                                RR 13.12
                              </span>
                            </span>
                            &nbsp;
                          </span>
                        </div>
                      </div>
                      <div className="col-md-7 ">
                        <div className="text-end ">
                          <p className="m-b-0 ">
                            IND Needed 35 runs from 16 balls
                          </p>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 ">
                            <p className="text-end ball-by-ball m-t-10 ">
                              <span className="ball-runs m-l-5  ng-star-inserted">
                                3
                              </span>

                              <span className="ball-runs m-l-5  wicket ng-star-inserted">
                                ww
                              </span>

                              <span className="ball-runs m-l-5  wicket ng-star-inserted">
                                ww
                              </span>

                              <span className="ball-runs m-l-5  ng-star-inserted">
                                2
                              </span>

                              <span className="ball-runs m-l-5  ng-star-inserted">
                                2
                              </span>

                              <span className="ball-runs m-l-5  wicket ng-star-inserted">
                                ww
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedTab == "game" ? (
                <div className=" ng-star-inserted">
                  <div className="casino-video ">
                    <div className="video-box-container ">
                      <div className="casino-video-box  ng-star-inserted">
                        <CasinoVideo id={CASINO_VIDEO.fiveFiveCricket} />
                      </div>
                    </div>
                    <div className=" ng-star-inserted">
                      <div className="casino-video-cards  ng-star-inserted">
                        {casinoData?.data?.response?.cards[0]?.map((item) => (
                          <div className="mt-1  ng-star-inserted">
                            <div className="">
                              <img
                                className=""
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <Timer value={casinoData?.data?.response?.autoTime} />
                  </div>

                  <div
                    id="scoreboard-box"
                    className="d-lg-none d-block  ng-star-inserted"
                  >
                    <div className="scorecard scorecard-mobile m-b-5 ">
                      <div className="score-inner ">
                        <div className="container-fluid container-fluid-5 ">
                          <p className="team-1 row row5 ">
                            <span className="team-name col-6 ">
                              AUS 66-3 (5.0)
                            </span>
                            <span className="team-name col-6 text-end ">
                              IND 32-3 (2.2)
                            </span>
                          </p>
                        </div>
                        <div className="container-fluid container-fluid-5 ">
                          <p className="match-status row row5 ">
                            <span className="col-9 " />
                          </p>
                          <p className="m-b-0 ">
                            IND Needed 35 runs from 16 balls
                          </p>
                          <p />
                          <div className="match-status row row5 ">
                            <span className="col-3 ">
                              <span className=" ng-star-inserted">
                                CRR 13.71
                              </span>
                            </span>
                            <span className="col-3  ng-star-inserted">
                              <span className=" ng-star-inserted">
                                RR 13.12
                              </span>
                            </span>

                            <span className="text-end col-6 ">
                              <span className="ball-runs mr-1  ng-star-inserted">
                                3
                              </span>

                              <span className="ball-runs mr-1  wicket ng-star-inserted">
                                ww
                              </span>

                              <span className="ball-runs mr-1  wicket ng-star-inserted">
                                ww
                              </span>

                              <span className="ball-runs mr-1  ng-star-inserted">
                                2
                              </span>

                              <span className="ball-runs mr-1  ng-star-inserted">
                                2
                              </span>

                              <span className="ball-runs mr-1  wicket ng-star-inserted">
                                ww
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="casino-detail ">
                    <div className=" ng-star-inserted">
                      <div className="casino-detail ">
                        <div className="desktop-design d-lg-block d-none ">
                          <div className="game-market market-4 ">
                            <div className="market-title ">
                              <span className="">Bookmaker Market</span>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#BookMarkerModal"
                                type="button"
                                className="rules_btn "
                              >
                                <i className="fas fa-info-circle " />
                              </a>
                            </div>
                            <div className="market-header ">
                              <div className="market-nation-detail ">
                                <span className="market-nation-name " />
                              </div>
                              <div className="market-odd-box no-border d-none d-md-block " />
                              <div className="market-odd-box no-border d-none d-md-block " />
                              <div className="market-odd-box back ">
                                <b className="">Back</b>
                              </div>
                              <div className="market-odd-box lay ">
                                <b className="">Lay</b>
                              </div>
                              <div className="market-odd-box no-border " />
                              <div className="market-odd-box no-border " />
                            </div>
                            <div data-title="OPEN" className="market-body ">
                              <div
                                data-title="ACTIVE"
                                className="market-row  ng-star-inserted"
                              >
                                <div className="market-nation-detail ">
                                  <span className="market-nation-name ">
                                    AUS
                                  </span>
                                  <div className="market-nation-book ">
                                    <span
                                      id="book_1"
                                      className="float-left book "
                                      style={{ color: "black" }}
                                    >
                                      <span className=""> 0 </span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  data-title="SUSPENDED"
                                  className="Market-odd-row-box  suspended-table"
                                >
                                  <div className="back2 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                  <div className="back1 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                  <div className="back market-odd-box ">
                                    <span className="market-odd "> 0</span>
                                    <span className="market-volume "> 0 </span>
                                  </div>
                                  <div className="lay market-odd-box ">
                                    <span className="market-odd "> 0</span>
                                    <span className="market-volume "> 0 </span>
                                  </div>
                                  <div className="lay1 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                  <div className="lay2 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                </div>
                              </div>
                              <div
                                data-title="ACTIVE"
                                className="market-row  ng-star-inserted"
                              >
                                <div className="market-nation-detail ">
                                  <span className="market-nation-name ">
                                    IND
                                  </span>
                                  <div className="market-nation-book ">
                                    <span
                                      id="book_1"
                                      className="float-left book "
                                      style={{ color: "black" }}
                                    >
                                      <span className=""> 0 </span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  data-title="SUSPENDED"
                                  className="Market-odd-row-box  suspended-table"
                                >
                                  <div className="back2 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                  <div className="back1 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                  <div className="back market-odd-box ">
                                    <span className="market-odd "> 0</span>
                                    <span className="market-volume "> 0 </span>
                                  </div>
                                  <div className="lay market-odd-box ">
                                    <span className="market-odd "> 0</span>
                                    <span className="market-volume "> 0 </span>
                                  </div>
                                  <div className="lay1 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                  <div className="lay2 market-odd-box ">
                                    <span className="market-odd " />
                                    <span className="market-volume " />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="game-market market-6 fancy-market-design ">
                            <div className="row row10 " />
                          </div>
                        </div>
                        <div className="mobile-design d-lg-none d-block ">
                          <div className="game-market market-4 ">
                            <div className="market-title ">
                              <span className="">Bookmaker Market</span>
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#BookMarkerModal"
                                href="javascript:void(0);"
                                className="rules_btn "
                              >
                                <i className="fas fa-info-circle " />
                              </a>
                            </div>
                            <div className="market-header ">
                              <div className="market-nation-detail ">
                                <span className="market-nation-name ">
                                  Max:
                                  <span className="">
                                    <span className=" ng-star-inserted">
                                      300000
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div className="market-odd-box no-border d-none d-md-block " />
                              <div className="market-odd-box no-border d-none d-md-block " />
                              <div className="market-odd-box back ">
                                <b className="">Back</b>
                              </div>
                              <div className="market-odd-box lay ">
                                <b className="">Lay</b>
                              </div>
                              <div className="market-odd-box no-border " />
                              <div className="market-odd-box no-border " />
                            </div>
                            <div data-title="OPEN" className="market-body ">
                              {casinoData?.data?.response?.oddDetailsDTOS?.map(
                                (item) => (
                                  <div
                                    data-title={item?.status}
                                    className="market-row  ng-star-inserted"
                                  >
                                    <div className="market-nation-detail ">
                                      <span className="market-nation-name ">
                                        AUS
                                      </span>
                                      <div className="market-nation-book ">
                                        <span
                                          id="book_1"
                                          className="float-left book "
                                          style={{ color: "black" }}
                                        >
                                          <span className=""> 0 </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      data-title={item?.status}
                                      className={`Market-odd-row-box  ${
                                        item?.status != "ACTIVE"
                                          ? "suspended-table"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        type="button"
                                        data-bs-toggle="modal"
                                        className="market-odd-box back2 "
                                      >
                                        <span className="market-odd " />
                                        <span className="market-volume " />
                                      </div>
                                      <div
                                        type="button"
                                        data-bs-toggle="modal"
                                        className="market-odd-box back1 "
                                      >
                                        <span className="market-odd " />
                                        <span className="market-volume " />
                                      </div>
                                      <div
                                        onClick={() =>
                                          setBet(
                                            item?.runnerName,
                                            casinoData,
                                            dispatch,
                                            "back"
                                          )
                                        }
                                        className="back market-odd-box "
                                      >
                                        <span className="market-odd ">
                                          {" "}
                                          {item?.back1}
                                        </span>
                                        <span className="market-volume ">
                                          {" "}
                                          {item?.backSize1}{" "}
                                        </span>
                                      </div>
                                      <div
                                        onClick={() =>
                                          setBet(
                                            item?.runnerName,
                                            casinoData,
                                            dispatch,
                                            "lay"
                                          )
                                        }
                                        className="lay market-odd-box "
                                      >
                                        <span className="market-odd ">
                                          {" "}
                                          {item?.lay1}
                                        </span>
                                        <span className="market-volume ">
                                          {" "}
                                          {item?.laySize1}{" "}
                                        </span>
                                      </div>
                                      <div
                                        type="button"
                                        data-bs-toggle="modal"
                                        className="market-odd-box lay1 "
                                      >
                                        <span className="market-odd " />
                                        <span className="market-volume " />
                                      </div>
                                      <div
                                        type="button"
                                        data-bs-toggle="modal"
                                        className="market-odd-box lay2 "
                                      >
                                        <span className="market-odd " />
                                        <span className="market-volume " />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="game-market market-6 fancy-monile-ddesign ">
                            <div className="row row10 " />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="casino-last-result-title ">
                        <span className="">Last Result</span>
                        <span className="">
                          <a className="">View All</a>
                        </span>
                      </div>
                      <div className="casino-last-results  ng-star-inserted">
                        {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                          (item) => (
                            <span
                              className={`result result-${
                                item?.mainWinner == "A" ? "a" : "b"
                              } ng-star-inserted`}
                            >
                              <span className="">{item?.mainWinner}</span>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <PlacedBet
                  casinoBets={casinoBets?.data?.response}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              )}
              <div className=" d-none matchbettable w-100 ng-star-inserted">
                <div className="table-responsive ">
                  <table className="table ">
                    <thead className="">
                      <tr className="">
                        <th className="">Matched Bet</th>
                        <th className="text-end ">Odds</th>
                        <th className="text-end ">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar right-sidebar casino-right-sidebar ">
            <div className="sidebar-box place-bet-container d-xl-block d-none ">
              <div className="sidebar-title ">
                <h4 className="">Place Bet</h4>
              </div>
            </div>
            <div className="sidebar-box my-bet-container d-xl-block d-none ">
              <div className="sidebar-title ">
                <h4 className="">My Bet</h4>
                <a className="sideviewmore ">View more</a>
              </div>
              <div className="my-bets ">
                <div className="table-responsive w-100 ">
                  <table className="table ">
                    <thead className="">
                      <tr className="">
                        <th className="">Matched Bet</th>
                        <th className="text-end ">Odds</th>
                        <th className="text-end ">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div className="sidebar-box sidebar-box-rules my-bet-container ">
              <app-casino-game-rules className="" _nghost-ng-c1761710207>
                <div
                  _ngcontent-ng-c1761710207
                  className="sidebar-title ng-star-inserted"
                >
                  <h4 _ngcontent-ng-c1761710207>Rules</h4>
                </div>
                <div _ngcontent-ng-c1761710207 className="row ng-star-inserted">
                  <div _ngcontent-ng-c1761710207 className="col-6 pe-1">
                    <div
                      _ngcontent-ng-c1761710207
                      className="my-bets cricket5-rules_table"
                    >
                      <div _ngcontent-ng-c1761710207 className="text-center">
                        AUS
                      </div>
                      <div
                        _ngcontent-ng-c1761710207
                        className="table-responsive super-over-rule"
                      >
                        <table _ngcontent-ng-c1761710207 className="table">
                          <thead _ngcontent-ng-c1761710207>
                            <tr _ngcontent-ng-c1761710207>
                              <th _ngcontent-ng-c1761710207>Cards</th>
                              <th
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody _ngcontent-ng-c1761710207>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardA.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                1 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card2.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                2 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card3.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                3 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card4.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                4 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card6.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                6 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card10.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                0 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardK.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                Wicket
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-ng-c1761710207 className="col-6 ps-1">
                    <div
                      _ngcontent-ng-c1761710207
                      className="my-bets cricket5-rules_table"
                    >
                      <div _ngcontent-ng-c1761710207 className="text-center">
                        IND
                      </div>
                      <div
                        _ngcontent-ng-c1761710207
                        className="table-responsive super-over-rule"
                      >
                        <table _ngcontent-ng-c1761710207 className="table">
                          <thead _ngcontent-ng-c1761710207>
                            <tr _ngcontent-ng-c1761710207>
                              <th _ngcontent-ng-c1761710207>Cards</th>
                              <th
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody _ngcontent-ng-c1761710207>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardA.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                1 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card2.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                2 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card3.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                3 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card4.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                4 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card6.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                6 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card10.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                0 Run
                              </td>
                            </tr>
                            <tr _ngcontent-ng-c1761710207>
                              <td _ngcontent-ng-c1761710207>
                                <img
                                  _ngcontent-ng-c1761710207
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardK.webp"
                                />
                                <span
                                  _ngcontent-ng-c1761710207
                                  className="mx-2"
                                >
                                  X
                                </span>
                                10
                              </td>
                              <td
                                _ngcontent-ng-c1761710207
                                className="text-end"
                              >
                                Wicket
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </app-casino-game-rules>
            </div>
          </div>
        </div>
      </div>
      <PlaceBet GAME_ID={GAME_ID} />
    </app-casino-match-detail>
  );
}
