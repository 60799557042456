import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import { toast } from "react-hot-toast";
export default function ChangePassword() {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const changePassword = async () => {
                                       if (
                                         password ===
                                           "" ||
                                         confirmPassword ===
                                           "" ||
                                         oldPassword ===
                                           ""
                                       ) {
                                         toast.error(
                                           "Please fill all the fields"
                                         );
                                         return;
                                       }
                                       if (
                                         password !==
                                         confirmPassword
                                       ) {
                                         toast.error(
                                           "Password and confirm password does not match"
                                         );
                                         return;
                                       }
                                       if (
                                         password ===
                                         oldPassword
                                       ) {
                                         toast.error(
                                           "New password and old password cannot be same"
                                         );
                                         return;
                                       }

                                       var {
                                         data,
                                         code,
                                         message,
                                         response,
                                         error,
                                       } = await changePasswordAPI(
                                         {
                                           newPassword: Encrypt(
                                             password
                                           ),
                                           password: Encrypt(
                                             oldPassword
                                           ),
                                           resetRequired: false,
                                           uid:
                                             userData.id,
                                         },
                                         userData.token
                                       );
                                       if (
                                         code ===
                                         200
                                       ) {
                                         toast.success(
                                           "Password changed successfully"
                                         );
                                         dispatch(
                                           {
                                             type:
                                               "accountData/logOut",
                                             payload: {},
                                           }
                                         );
                                         dispatch(
                                           {
                                             type:
                                               "gameData/logOut",
                                             payload: {},
                                           }
                                         );
                                       } else {
                                         toast.error(
                                           "Wrong password"
                                         );
                                       }
                                     };
  const isMobile = window.innerWidth < 768;
  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
      }}
      className={`col-md-12 report-main-content m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Change Password</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          <div className="row row5 mt-2">
            <div className={isMobile ? "col-12" : "col-4"}>
              <div className="form-group">
                <label>Current Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  value={password}
                  className="form-control"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <button onClick={changePassword} className="btn btn-primary">
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
