import React from "react";
import "./otp.scoped.css";
import logo from "../assets/logo.png";
import { useDispatch } from "react-redux";
import { resendCodeAPI, verifyCodeAPI } from "../service/AuthService";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
export default function Otp() {
  const [otp, setOtp] = React.useState({
    otp1: { value: "", ref: React.createRef() },
    otp2: { value: "", ref: React.createRef() },
    otp3: { value: "", ref: React.createRef() },
    otp4: { value: "", ref: React.createRef() },
    otp5: { value: "", ref: React.createRef() },
    otp6: { value: "", ref: React.createRef() },
  });

  const location = useLocation();
  const [userName, setUserName] = React.useState(location?.state?.userName);
  const dispatch = useDispatch();
  const handleResendCode = async () => {
    const { response, code } = await resendCodeAPI(userName);
    if (code == 200) {
      toast.success("Code sent successfully");
    }
  };
  const handleSubmit = async () => {
    const { response, code } = await verifyCodeAPI(
      userName,
      Object.values(otp)
        .map((otp) => otp.value)
        .join("")
    );
    if (code == 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      toast.success("Login Successfull");
    } else {
      toast.error(response);
    }
  };
  const handleOtpChange = (e, key, index) => {
    if (e.target.value.length > 1) {
      return;
    }
    setOtp({
      ...otp,
      [key]: { value: e.target.value },
    });
    if (e.target.value.length > 0 && index < Object.keys(otp).length - 1) {
      otp[Object.keys(otp)[index + 1]].ref.current.focus();
    }
  };

  return (
    <div className="login-wrapper snipcss-vIwQ5">
      <div className="text-center logo-login mb-3">
        <img src={logo} />
      </div>
      <div className="login-form loginInner1 authentication">
        <div className="featured-box-login featured-box-secundary default">
          <h4 className="text-center">
            Security Code Verification Using Telegram App
          </h4>
          <div className="mt-3 text-center">
            Enter 6-digit code from your telegram bot{" "}
            <span>
              <a href="#" onClick={handleResendCode}>
                Resend Code
              </a>
            </span>
          </div>
          <div role="form" autoComplete="off" method="post" className="mt-3">
            <div id="style-Rjo7S" className="style-Rjo7S">
              <div is-input-num="true" id="style-NWE8h" className="style-NWE8h">
                {Object.keys(otp).map((key, index) => (
                  <div className="style-yZwpn">
                    <input
                      min={0}
                      max={9}
                      maxLength={1}
                      pattern="[0-9]"
                      onChange={(e) => handleOtpChange(e, key, index)}
                      type="tel"
                      ref={otp[key].ref}
                      className="otp-input"
                      name={key + (index + 1)}
                      value={otp[key].value}
                    />
                  </div>
                ))}
              </div>
            </div>
            <button
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "black",
              }}
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
