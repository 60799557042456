import React from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useQuery } from "react-query";
import CasinoVideo from "./components/CasinoVideo";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import { useDispatch } from "react-redux";
import PlaceBet from "./components/PlaceBet";
import TimerGeneric from "./components/TimerGeneric";
import Timer from "./components/Timer";
import PlacedBet from "./components/PlacedBet";
export default function SuperOver() {
  const GAME_ID = "SUPEROVER";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const [selectedTab, setSelectedTab] = React.useState("game");
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  return (
    <div
      style={{
        width: "100%",
      }}
      className=" ng-star-inserted"
    >
      <div className="main-container ">
        <div className="center-main-container casino-page  ng-star-inserted">
          {
            <div className="center-container ">
              <div className="casino-page-container  super-over">
                <div className="casino-title-header-mobile d-xl-none ">
                  <ul className="nav nav-tabs menu-tabs ">
                    <li
                      onClick={() => setSelectedTab("game")}
                      className="nav-item "
                    >
                      <div className=" active nav-link">Game</div>
                    </li>
                    <li
                      onClick={() => setSelectedTab("placedBet")}
                      className="nav-item "
                    >
                      <div className=" nav-link">
                        {" "}
                        Placed Bet ({casinoBets?.data?.response?.length}){" "}
                      </div>
                    </li>
                  </ul>
                  <div className="pe-2 ">
                    <span className="casino-rid ">
                      <a className="d-block ">
                        <small className="">Rules</small>
                      </a>{" "}
                      Round ID:{" "}
                      <span className=" ng-star-inserted">
                        {casinoData?.data?.response?.marketId}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="casino-header ">
                  <span className="casino-name ">
                    {" "}
                    Super Over
                    <a className="ms-1 d-xl-inline d-none ">
                      <small className="">Rules</small>
                    </a>
                  </span>
                  <span className="casino-rid d-none d-xl-inline-block ">
                    <small className="">
                      Round ID:{" "}
                      <span className=" ng-star-inserted">
                        {casinoData?.data?.response?.marketId}
                      </span>
                    </small>
                    <span className=" ng-star-inserted">
                      {" "}
                      | Min:
                      <span className=" ng-star-inserted"> 100 </span>| Max:
                      <span className=" ng-star-inserted"> 500000 </span>
                    </span>
                  </span>
                </div>
                <div className="scorecard mb-1 d-lg-block d-none  ng-star-inserted">
                  <div className="row ">
                    <div className="col-md-5  ng-star-inserted">
                      <p className="team-1 row ">
                        <span className="team-name col-md-3 ">ENG</span>
                        <span className="score col-md-9 text-end ">
                          8-0 (0.3)
                        </span>
                      </p>
                    </div>
                    <div className="col-md-7  ng-star-inserted">
                      <div className="row ">
                        <div className="col-md-5 ">
                          <div className="row ">
                            <span className="team-name ">&nbsp; </span>
                          </div>
                          <div className="row m-t-10 ">
                            <span className="team-name " />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5  ng-star-inserted">
                      <p className="team-1 row ">
                        <span className="team-name col-md-3 ">RSA</span>
                        <span className="score col-md-9 text-end ">
                          0-0 (0.0)
                        </span>
                      </p>
                    </div>
                    <div className="col-md-7  ng-star-inserted">
                      <div className="row ">
                        <div className="col-md-5 ">
                          <div className="row ">
                            <span className="team-name ">&nbsp; </span>
                          </div>
                          <div className="row m-t-10 ">
                            <span className="team-name " />
                          </div>
                        </div>
                        <div className="col-md-7  ng-star-inserted">
                          <div className="text-end ">
                            <p className="m-b-0 " />
                          </div>
                          <div className="row ">
                            <div className="col-md-12 ">
                              <p className="text-end ball-by-ball m-t-10 ">
                                {casinoData?.data?.response?.cards[0]?.map(
                                  (item) => (
                                    <span className="ball-runs m-l-5  dot ng-star-inserted">
                                      item
                                    </span>
                                  )
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedTab == "game" ? (
                  <div className=" ng-star-inserted">
                    <div className="casino-video ">
                      <div className="video-box-container ">
                        <div className="casino-video-box  ng-star-inserted">
                          <CasinoVideo id={CASINO_VIDEO.superOver} />
                        </div>
                      </div>
                      <div className=" ng-star-inserted">
                        <div className="casino-video-cards  ng-star-inserted">
                          {casinoData?.data?.response?.cards?.length > 0 &&
                            casinoData?.data?.response?.cards[0]?.map(
                              (item) => (
                                <div className="mt-1  ng-star-inserted">
                                  <div className="">
                                    <img
                                      className=""
                                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/${item}.webp`}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>

                      <Timer value={casinoData?.data?.response?.autoTime} />
                    </div>
                    <div
                      id="scoreboard-box"
                      className="d-lg-none d-block  ng-star-inserted"
                    >
                      <div className="scorecard scorecard-mobile m-b-5 ">
                        <div className="score-inner ">
                          <div className="container-fluid container-fluid-5 ">
                            <p className="team-1 row row5 ">
                              <span className="team-name col-6  ng-star-inserted">
                                ENG 8-0 (0.3)
                              </span>

                              <span className="team-name col-6 text-end  ng-star-inserted">
                                RSA 0-0 (0.0)
                              </span>
                            </p>
                          </div>
                          <div className="container-fluid container-fluid-5 ">
                            <p className="match-status row row5 ">
                              <span className="col-9 " />
                            </p>
                            <div className="match-status row row5 ">
                              <span className="col-3 " />
                              <span className="col-3 " />
                              <span className="text-end col-6 ">
                                <span className="ball-runs mr-1  dot ng-star-inserted">
                                  0
                                </span>

                                <span className="ball-runs mr-1 six  ng-star-inserted">
                                  6
                                </span>

                                <span className="ball-runs mr-1  run ng-star-inserted">
                                  2
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="casino-detail ">
                      <div className=" ng-star-inserted">
                        <div className="casino-detail ">
                          <div className="desktop-design d-lg-block d-none ">
                            <div className="game-market market-4 ">
                              <div className="market-title ">
                                <span className="">Bookmaker Market</span>
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#BookMarkerModal"
                                  type="button"
                                  className="rules_btn "
                                >
                                  <i className="fas fa-info-circle " />
                                </a>
                              </div>
                              <div className="market-header ">
                                <div className="market-nation-detail ">
                                  <span className="market-nation-name " />
                                </div>
                                <div className="market-odd-box no-border d-none d-md-block " />
                                <div className="market-odd-box no-border d-none d-md-block " />
                                <div className="market-odd-box back ">
                                  <b className="">Back</b>
                                </div>
                                <div className="market-odd-box lay ">
                                  <b className="">Lay</b>
                                </div>
                                <div className="market-odd-box no-border " />
                                <div className="market-odd-box no-border " />
                              </div>
                              <div data-title="OPEN" className="market-body ">
                                {casinoData?.data?.response?.oddDetailsDTOS?.map(
                                  (item) => (
                                    <div
                                      data-title={item?.status}
                                      className="market-row  ng-star-inserted"
                                    >
                                      <div className="market-nation-detail ">
                                        <span className="market-nation-name ">
                                          {item?.runnerName}
                                        </span>
                                        <div className="market-nation-book ">
                                          <span
                                            id="book_1"
                                            className="float-left book "
                                            style={{ color: "black" }}
                                          >
                                            <span className=""> 0 </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        data-title={item?.status}
                                        className={`Market-odd-row-box  ${item?.status !=
                                          "ACTIVE" && "suspended-table"}`}
                                      >
                                        <div className="back2 market-odd-box ">
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                        <div className="back1 market-odd-box ">
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                        <div
                                          onClick={() =>
                                            setBet(
                                              item?.runnerName,
                                              casinoData,
                                              dispatch,
                                              "back"
                                            )
                                          }
                                          className="back market-odd-box "
                                        >
                                          <span className="market-odd ">
                                            {" "}
                                            {getOddForEntity(
                                              item?.runnerName,
                                              casinoData
                                            )}
                                          </span>
                                          <span className="market-volume ">
                                            {" "}
                                            {getOddForEntity(
                                              item?.runnerName,
                                              casinoData,
                                              true,
                                              true
                                            )}{" "}
                                          </span>
                                        </div>
                                        <div
                                          onClick={() =>
                                            setBet(
                                              item?.runnerName,
                                              casinoData,
                                              dispatch,
                                              "lay"
                                            )
                                          }
                                          className="lay market-odd-box "
                                        >
                                          <span className="market-odd ">
                                            {" "}
                                            {getOddForEntity(
                                              item?.runnerName,
                                              casinoData
                                            )}
                                          </span>
                                          <span className="market-volume ">
                                            {getOddForEntity(
                                              item?.runnerName,
                                              casinoData,
                                              false,
                                              true
                                            )}
                                          </span>
                                        </div>
                                        <div className="lay1 market-odd-box ">
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                        <div className="lay2 market-odd-box ">
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="game-market market-6 fancy-market-design ">
                              <div className="row row10 " />
                            </div>
                          </div>
                          <div className="mobile-design d-lg-none d-block ">
                            <div className="game-market market-4 ">
                              <div className="market-title ">
                                <span className="">Bookmaker Market</span>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#BookMarkerModal"
                                  href="javascript:void(0);"
                                  className="rules_btn "
                                >
                                  <i className="fas fa-info-circle " />
                                </a>
                              </div>
                              <div className="market-header ">
                                <div className="market-nation-detail ">
                                  <span className="market-nation-name ">
                                    {" "}
                                    Max:{" "}
                                    <span className="">
                                      <span className=" ng-star-inserted">
                                        {" "}
                                        500000{" "}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div className="market-odd-box no-border d-none d-md-block " />
                                <div className="market-odd-box no-border d-none d-md-block " />
                                <div className="market-odd-box back ">
                                  <b className="">Back</b>
                                </div>
                                <div className="market-odd-box lay ">
                                  <b className="">Lay</b>
                                </div>
                                <div className="market-odd-box no-border " />
                                <div className="market-odd-box no-border " />
                              </div>
                              <div data-title="OPEN" className="market-body ">
                                {casinoData?.data?.response?.oddDetailsDTOS?.map(
                                  (item) => (
                                    <div
                                      data-title={item?.status}
                                      className="market-row  ng-star-inserted"
                                    >
                                      <div className="market-nation-detail ">
                                        <span className="market-nation-name ">
                                          {item?.runnerName}
                                        </span>
                                        <div className="market-nation-book ">
                                          <span
                                            id="book_1"
                                            className="float-left book "
                                            style={{ color: "black" }}
                                          >
                                            <span className=""> 0 </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        data-title={item?.status}
                                        className={`Market-odd-row-box ${item?.status !=
                                          "ACTIVE" && "suspended-table"}`}
                                      >
                                        <div
                                          type="button"
                                          data-bs-toggle="modal"
                                          className="market-odd-box back2 "
                                        >
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                        <div
                                          type="button"
                                          data-bs-toggle="modal"
                                          className="market-odd-box back1 "
                                        >
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                        <div
                                          onClick={() =>
                                            setBet(
                                              item?.runnerName,
                                              casinoData,
                                              dispatch,
                                              "back"
                                            )
                                          }
                                          className="back market-odd-box "
                                        >
                                          <span className="market-odd ">
                                            {" "}
                                            {getOddForEntity(
                                              item?.runnerName,
                                              casinoData
                                            )}
                                          </span>
                                          <span className="market-volume ">
                                            {" "}
                                            1M{" "}
                                          </span>
                                        </div>
                                        <div
                                          onClick={() =>
                                            setBet(
                                              item?.runnerName,
                                              casinoData,
                                              dispatch,
                                              "lay"
                                            )
                                          }
                                          className="lay market-odd-box "
                                        >
                                          <span className="market-odd ">
                                            {" "}
                                            {getOddForEntity(
                                              item?.runnerName,
                                              casinoData,
                                              false
                                            )}
                                          </span>
                                          <span className="market-volume ">
                                            {" "}
                                            1M{" "}
                                          </span>
                                        </div>
                                        <div
                                          type="button"
                                          data-bs-toggle="modal"
                                          className="market-odd-box lay1 "
                                        >
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                        <div
                                          type="button"
                                          data-bs-toggle="modal"
                                          className="market-odd-box lay2 "
                                        >
                                          <span className="market-odd " />
                                          <span className="market-volume " />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="game-market market-6 fancy-monile-ddesign ">
                              <div className="row row10 " />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="casino-last-result-title ">
                          <span className="">Last Result</span>
                          <span className="">
                            <a className="">View All</a>
                          </span>
                        </div>
                        <div className="casino-last-results  ng-star-inserted">
                          {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                            (item) => (
                              <span
                                className={` result result-${
                                  item?.mainWinner == "E" ? "a" : "b"
                                } ng-star-inserted`}
                              >
                                <span className=""> {item?.mainWinner}</span>
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <PlacedBet
                    setSelectedTab={setSelectedTab}
                    bets={casinoBets?.data?.response}
                    casino={GAME_ID}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </div>
      <PlaceBet GAME_ID={GAME_ID} />
    </div>
  );
}
