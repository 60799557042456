import moment from "moment";
import React from "react";
export default function DetailModal({ logData, isModalOpen, setIsModalOpen }) {
  return (
    <div id="__BVID__30___BV_modal_outer_">
      <div
        id="__BVID__30"
        role="dialog"
        aria-labelledby="__BVID__30___BV_modal_title_"
        aria-describedby="__BVID__30___BV_modal_body_"
        className="modal fade show place-bet-modal"
        aria-modal="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-md">
          <span tabIndex={0} />
          <div
            role="document"
            id="__BVID__30___BV_modal_content_"
            tabIndex={-1}
            className="modal-content"
          >
            <div
              style={{
                padding: "10px",
              }}
              className="container-fluid container-fluid-5"
            >
              <div className="row row5">
                <div className="col-12 text-center">
                  <div
                    id="match_delete  "
                    role="radiogroup"
                    tabIndex={-1}
                    className=""
                  >
                    <div className="custom-control  custom-control-inline custom-radio">
                      <input
                        id="matched"
                        type="radio"
                        name="match_delete"
                        autoComplete="off"
                        defaultValue={1}
                        className="custom-control-input"
                      />{" "}
                      <label
                        htmlFor="matched"
                        className="custom-control-label"
                      >
                        <span>Matched</span>
                      </label>
                    </div>
                    <div className="custom-control custom-control-inline custom-radio">
                      <input
                        id="deleteed"
                        type="radio"
                        name="match_delete"
                        autoComplete="off"
                        defaultValue={2}
                        className="custom-control-input"
                      />{" "}
                      <label
                        htmlFor="deleteed"
                        className="custom-control-label"
                      >
                        <span>Deleted</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row5">
                <div className="col-12">
                  {logData?.betResponseDTOList?.map((item) => (
                    <div
                      style={{ fontSize: "x-small", color: "black" }}
                      className={`account-statement
                              ${
                                item?.lay_rate || item?.lay_run
                                  ? "lay"
                                  : "back"
                              }`}
                    >
                      <div className="row row5">
                        <div className="col-4">
                          <div style={{ color: "black" }}>
                            <b>Nation:</b>{" "}
                            {item?.runner_name
                              ? item?.runner_name
                              : item?.event_name}{" "}
                          </div>
                          <div style={{ color: "black" }} className="">
                            <b style={{}}>Placed Date:</b>{" "}
                            {moment(item?.created_date + "Z").format(
                              "DD MM YYYY hh:mm A"
                            )}
                          </div>
                          <div style={{ color: "black" }}>
                            <b>Matched Date:</b>{" "}
                            {moment(item?.created_date + "Z").format(
                              "DD MM YYYY hh:mm A"
                            )}{" "}
                          </div>
                        </div>
                        <div
                          style={{ color: "black" }}
                          className="col-2 text-right"
                        >
                          <div style={{ color: "black" }}>
                            <b>Rate</b>
                          </div>
                          <div style={{ color: "black" }}>
                            {item?.lay_rate || item?.back_rate
                              ? item?.lay_rate
                                ? item?.lay_rate
                                : item?.back_rate
                              : item?.lay_run
                              ? item?.lay_run
                              : item?.back_run}
                          </div>
                        </div>
                        <div
                          style={{ color: "black" }}
                          className="col-2 text-right"
                        >
                          <div style={{ color: "black" }}>
                            <b>Run</b>
                          </div>
                          <div style={{ color: "black" }}>
                            {item?.lay_run ? item?.lay_run : item?.back_run}
                          </div>
                        </div>
                        <div className="col-2 text-right">
                          <div style={{ color: "black", width: "43px" }}>
                            <b>Amount</b>
                          </div>
                          <div style={{ color: "black" }}>{item?.amount}</div>
                        </div>
                        <div className="col-2 text-right">
                          <div style={{ color: "black" }}>
                            <b>W&amp;L</b>
                          </div>
                          <div
                            style={{
                              color: item?.profit ? "green" : "red",
                              marginRight: "5px",
                            }}
                          >
                            {item?.profit ? item?.profit : item?.loss}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <footer
              id="__BVID__50___BV_modal_footer_"
              className="modal-footer"
            >
              <button
                onClick={() => setIsModalOpen(null)}
                type="button"
                className="btn btn-danger btn-sm"
              >
                Cancel
              </button>
            </footer>
          </div>
        </div>
      </div>
      <span tabIndex={0} />
      <div id="__BVID__30___BV_modal_backdrop_" className="modal-backdrop" />
    </div>
  );
}
