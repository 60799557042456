import React from "react";
import { useParams } from "react-router-dom";
import {
  getMatchDetailsSoccerAPI,
  getMyBetsSoccerAPI,
  getSoccerEventDetailsAPI,
  getSoccerOddsByMatchIdAPI,
  placeSoccerBetAPI,
} from "../../../service/soccer&tennis";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import GameSidebar from "../gameComponents/GameSidebar";
import { casinoPL, cricketMatchOddsPL } from "../profitLoss";
import { toast } from "react-hot-toast";
import { getLiveStreamingUrlAPI } from "../../../service/AuthService";
import { DEFAULT_BET_AMOUNT } from "../../../utils/constants";
export default function SoccerGame() {
  const { id } = useParams();
  const [gameOdds, setGameOdds] = useState({});
  const [gameEvent, setGameEvent] = useState([]);
  const [betPayload, setBetPayload] = useState(null);
  const [eventExposureAndSettings, setEventExposureAndSettings] = useState({});
  const [userBets, setUserBets] = useState([]);
  const [channelId, setChannelId] = useState("");
  const [isTvOpen, setIsTvOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("odds");
  const getGameEventDetails = useCallback(async () => {
    const { response, code } = await getSoccerEventDetailsAPI(id);
    if (code === 200) {
      setGameEvent(response);
    }
  }, [id]);
  const getSoccerOddsByMatchId = useCallback(async () => {
    const { response, code } = await getSoccerOddsByMatchIdAPI(id);
    if (code === 200) {
      setGameOdds(response);
    }
  }, [id]);
  const getEventExposureAndSettings = useCallback(async () => {
    const { response, code } = await getMatchDetailsSoccerAPI(id);
    if (code === 200) {
      setEventExposureAndSettings(response);
    }
  }, [id]);

  const handleOddClick = (
    odd,
    oddSize,
    item,
    isBack = true,
    forOther = false
  ) => {
    handleAmountChange();
    let calculation = casinoPL(
      odd,
      betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
      isBack ? "back" : "lay"
    );
    let payload = {
      profit: parseFloat(calculation.profit).toFixed(2),
      loss: parseFloat(calculation.loss).toFixed(2),
      marketId: forOther
        ? forOther?.marketId
        : gameOdds?.matchOddsResponseDTO[0]?.marketId,
      selectionId: item.selectionId,
      runnerName: item.runnerName,
      type: isBack ? "back" : "lay",
      eventId: id,
      marketName: forOther
        ? forOther?.marketId
        : gameOdds?.matchOddsResponseDTO[0]?.marketName,
      gameType: forOther
        ? forOther?.marketId
        : gameOdds?.matchOddsResponseDTO[0]?.marketName,
      odd: odd,
      oddSize: oddSize,
      amount: betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
    };
    payload[isBack ? "back" : "lay"] = parseFloat(odd);
    setBetPayload(payload);
  };
  const fetchMyBets = useCallback(async () => {
    const { response, code } = await getMyBetsSoccerAPI(id);
    setUserBets(code == 200 ? response : []);
  }, [id]);
  useEffect(() => {
    fetchMyBets();
    let interval = setInterval(() => {
      fetchMyBets();
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const applyBlinkEffect = (element) => {
    element.classList.add("blink");

    setTimeout(() => {
      element.classList.remove("blink");
    }, 1000);
  };

  useEffect(() => {
    const layButtons = document.querySelectorAll(".box-1");
    const observers = [];
    layButtons.forEach((layButton) => {
      const observer = new MutationObserver(() => {
        applyBlinkEffect(layButton);
      });
      observer.observe(layButton, {
        childList: true,
        subtree: true,
        characterData: true,
      });
      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [gameOdds]);
  const handleBetPlace = async () => {
    const { response, code } = await placeSoccerBetAPI(betPayload);
    if (code === 200) {
      setBetPayload(null);
      toast.success("Bet Placed Successfully");
    } else if (code == "ERR_BAD_RESPONSE") {
      setBetPayload(null);
      toast.error("Something went wrong");
    } else {
      setBetPayload(null);
      toast.error(response);
    }
  };
  const handleAmountChange = (amount = DEFAULT_BET_AMOUNT) => {
    let calculation = casinoPL(
      betPayload?.odd,
      betPayload?.amount ? betPayload?.amount : amount,
      betPayload?.type
    );
    setBetPayload({
      ...betPayload,
      profit: parseFloat(calculation.profit).toFixed(2),
      loss: parseFloat(calculation.loss).toFixed(2),
      amount: amount,
    });
  };
  useEffect(() => {
    getGameEventDetails();
    getSoccerOddsByMatchId();
    getEventExposureAndSettings();
    let interval = setInterval(() => {
      getSoccerOddsByMatchId();
      getEventExposureAndSettings();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getLiveStreamingUrl = async () => {
    const { response } = await getLiveStreamingUrlAPI(gameEvent?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [gameEvent?.eventId]);
  const checkIfFloat = (value) => {
    return value % 1 != 0 ? parseFloat(value).toFixed(2) : parseInt(value);
  };
  const amountFormatter = (amount, toL = true) => {
    if (amount > 10000000) {
      return amount / 10000000 + "Cr";
    } else if (amount > 100000) {
      return amount / 100000 + "L";
    } else if (amount > 1000) {
      return amount / 1000 + "K";
    } else {
      return amount;
    }
  };
  return (
    <div className="center-main-container detail-page">
      <div className="center-container">
        <div className="detail-page-container">
          <div className="game-header">
            <span>{gameEvent?.eventName}</span>
            <span className="float-right">
              {moment(gameEvent?.eventDate).format("DD-MM-YYYY HH:mm")}
            </span>
          </div>
          <div className="scorecard">
            <iframe
              style={{
                width: "100%",
                height: "auto",
              }}
              src={`https://animscore.proexch.in/#/score1/${
                gameEvent?.eventId
              }`}
            />
          </div>

          <ul className="nav nav-tabs d-xl-none menu-tabs">
            <li onClick={() => setActiveTab("odds")} className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab">
                Odds
              </a>
            </li>
            <li onClick={() => setActiveTab("bets")} className="nav-item">
              <a className="nav-link" data-bs-toggle="tab">
                Matched Bet ({userBets?.length})
              </a>
            </li>
            <li onClick={() => setIsTvOpen(!isTvOpen)} className="nav-item">
              <a className="nav-link">
                <i className="fas fa-tv" />
              </a>
            </li>
          </ul>
          {isTvOpen && (
            <iframe
              style={{
                width: "100%",
              }}
              src={`https://video.proexch.in/tv/static?chid=${channelId}`}
            />
          )}
          {activeTab == "bets" ? (
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>Matched Bet</th>
                    <th className="text-end">Odds</th>
                    <th className="text-end">Stake</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets?.map((bet) => (
                    <tr className={bet?.back ? "back" : "lay"}>
                      <td>
                        <span className="bet-name">{bet?.runnerName}</span>
                        <span className="bet-type">{bet?.type}</span>
                      </td>
                      <td className="text-end">
                        <span className="bet-odd">
                          {bet?.back ? bet?.back : bet?.lay}
                        </span>
                      </td>
                      <td className="text-end">
                        <span className="bet-stake">{bet?.amount}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {gameOdds?.matchOddsResponseDTO && (
                <div className="game-market market-4 ">
                  <div className="market-title">
                    <span>MATCH_ODDS</span>
                  </div>
                  <div className="market-header">
                    <div className="market-nation-detail">
                      <span className="market-nation-name">
                        Max:{" "}
                        {amountFormatter(eventExposureAndSettings?.maxStack)}
                      </span>
                    </div>
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box back">
                      <b>Back</b>
                    </div>
                    <div className="market-odd-box lay">
                      <b>Lay</b>
                    </div>
                    <div className="market-odd-box" />
                    <div className="market-odd-box no-border" />
                  </div>
                  <div
                    className="market-body "
                    data-title={gameOdds?.matchOddsResponseDTO[0]?.marketStatus?.toLowerCase()}
                  >
                    {gameOdds?.matchOddsResponseDTO &&
                      gameOdds?.matchOddsResponseDTO[0]?.oddDetailsDTOS?.map(
                        (item) => (
                          <div
                            className={`market-row ${
                              item?.status?.toUpperCase() == "SUSPENDED"
                                ? "suspended-row"
                                : ""
                            }`}
                            data-title={item?.status?.toUpperCase()}
                          >
                            <div className="market-nation-detail">
                              <span className="market-nation-name">
                                {item?.runnerName}
                              </span>

                              <div className="market-nation-book">
                                <div className="market-book">
                                  <span
                                    style={{
                                      color:
                                        eventExposureAndSettings?.plExposureResponseDTOs
                                          ?.find(
                                            (i) =>
                                              i.marketIdExternal ===
                                              gameOdds?.matchOddsResponseDTO[0]
                                                ?.marketId
                                          )
                                          ?.runnerPlExposureDTOS?.find(
                                            (runner) =>
                                              runner.selectionId ==
                                              item.selectionId
                                          )?.exposure < 0
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {
                                      eventExposureAndSettings?.plExposureResponseDTOs
                                        ?.find(
                                          (i) =>
                                            i.marketIdExternal ===
                                            gameOdds?.matchOddsResponseDTO[0]
                                              ?.marketId
                                        )
                                        ?.runnerPlExposureDTOS?.find(
                                          (runner) =>
                                            runner.selectionId ==
                                            item.selectionId
                                        )?.exposure
                                    }
                                  </span>
                                </div>
                                {gameOdds?.matchOddsResponseDTO[0]?.marketId ===
                                  betPayload?.marketId && (
                                  <span
                                    class={`market-live-book d-none d-xl-block ${
                                      betPayload?.selectionId ===
                                      item?.selectionId
                                        ? "text-success"
                                        : "text-danger"
                                    } `}
                                  >
                                    {betPayload?.selectionId ===
                                    item?.selectionId
                                      ? betPayload?.profit
                                      : "-" + betPayload?.loss}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                handleOddClick(
                                  item?.back3,
                                  item?.backSize3,
                                  item
                                )
                              }
                              className="market-odd-box   back2 "
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.back3)}
                              </span>
                              <span className="market-volume">
                                {item?.backSize3}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                handleOddClick(
                                  item?.back2,
                                  item?.backSize2,
                                  item
                                )
                              }
                              className="market-odd-box  back1  "
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.back2)}
                              </span>
                              <span className="market-volume">
                                {item?.backSize2}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                handleOddClick(
                                  item?.back1,
                                  item?.backSize1,
                                  item
                                )
                              }
                              className="market-odd-box back   "
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.back1)}
                              </span>
                              <span className="market-volume">
                                {item?.backSize1}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                handleOddClick(
                                  item?.lay1,
                                  item?.laySize1,
                                  item,
                                  false
                                )
                              }
                              className="market-odd-box lay   "
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.lay1)}
                              </span>
                              <span className="market-volume">
                                {item?.laySize1}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                handleOddClick(
                                  item?.lay2,
                                  item?.laySize2,
                                  item,
                                  false
                                )
                              }
                              className="market-odd-box  lay1  "
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.lay2)}
                              </span>
                              <span className="market-volume">
                                {item?.laySize2}
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                handleOddClick(
                                  item?.lay3,
                                  item?.laySize3,
                                  item,
                                  false
                                )
                              }
                              className="market-odd-box   lay2 "
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.lay3)}
                              </span>
                              <span className="market-volume">
                                {item?.laySize3}
                              </span>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              )}
              {gameOdds?.other_market_odds &&
                gameOdds?.other_market_odds?.map((marketWiseOdds) => (
                  <div className="game-market market-4 ">
                    <div className="market-title">
                      <span>{marketWiseOdds?.market}</span>
                    </div>
                    <div className="market-header">
                      <div className="market-nation-detail">
                        <span className="market-nation-name">
                          Max:{" "}
                          {amountFormatter(eventExposureAndSettings?.maxStack)}
                        </span>
                      </div>
                      <div className="market-odd-box no-border d-none d-md-block" />
                      <div className="market-odd-box no-border d-none d-md-block" />
                      <div className="market-odd-box back">
                        <b>Back</b>
                      </div>
                      <div className="market-odd-box lay">
                        <b>Lay</b>
                      </div>
                      <div className="market-odd-box" />
                      <div className="market-odd-box no-border" />
                    </div>
                    <div
                      className="market-body "
                      data-title={marketWiseOdds?.marketStatus?.toUpperCase()}
                    >
                      {marketWiseOdds?.oddDetailsDTOS?.map((item) => (
                        <div
                          className={`market-row ${
                            item?.status?.toUpperCase() == "SUSPENDED"
                              ? "suspended-row"
                              : ""
                          }`}
                          data-title={marketWiseOdds?.marketStatus?.toUpperCase()}
                        >
                          <div className="market-nation-detail">
                            <span className="market-nation-name">
                              {item?.runnerName}
                            </span>

                            <div className="market-nation-book">
                              <div className="market-book">
                                <span
                                  style={{
                                    color:
                                      eventExposureAndSettings?.plExposureResponseDTOs
                                        ?.find(
                                          (i) =>
                                            i.marketIdExternal ===
                                            marketWiseOdds?.marketId
                                        )
                                        ?.runnerPlExposureDTOS?.find(
                                          (runner) =>
                                            runner.selectionId ==
                                            item.selectionId
                                        )?.exposure <
                                      0 <
                                      0
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {eventExposureAndSettings?.plExposureResponseDTOs
                                    ?.find(
                                      (i) =>
                                        i.marketIdExternal ===
                                        marketWiseOdds?.marketId
                                    )
                                    ?.runnerPlExposureDTOS?.find(
                                      (runner) =>
                                        runner.selectionId == item.selectionId
                                    )?.exposure < 0}
                                </span>
                              </div>
                              {marketWiseOdds?.marketId ===
                                betPayload?.marketId && (
                                <span
                                  class={`market-live-book d-none d-xl-block ${
                                    betPayload?.selectionId ===
                                    item?.selectionId
                                      ? "text-success"
                                      : "text-danger"
                                  } `}
                                >
                                  {betPayload?.selectionId === item?.selectionId
                                    ? betPayload?.profit
                                    : "-" + betPayload?.loss}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              handleOddClick(
                                item?.back3,
                                item?.backSize3,
                                item,
                                true,
                                marketWiseOdds
                              )
                            }
                            className="market-odd-box   back2 "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back3)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize3}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleOddClick(
                                item?.back2,
                                item?.backSize2,
                                item,
                                true,
                                marketWiseOdds
                              )
                            }
                            className="market-odd-box  back1  "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back2)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize2}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleOddClick(
                                item?.back1,
                                item?.backSize1,
                                item,
                                true,
                                marketWiseOdds
                              )
                            }
                            className="market-odd-box back   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back1)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize1}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleOddClick(
                                item?.lay1,
                                item?.laySize1,
                                item,
                                false,
                                marketWiseOdds
                              )
                            }
                            className="market-odd-box lay   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay1)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize1}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleOddClick(
                                item?.lay2,
                                item?.laySize2,
                                item,
                                false,
                                marketWiseOdds
                              )
                            }
                            className="market-odd-box  lay1  "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay2)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize2}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleOddClick(
                                item?.lay3,
                                item?.laySize3,
                                item,
                                false,
                                marketWiseOdds
                              )
                            }
                            className="market-odd-box   lay2 "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay3)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize3}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      <GameSidebar
        betPayload={betPayload}
        id={id}
        handleAmountChange={handleAmountChange}
        handleBetPlace={handleBetPlace}
        userBets={userBets}
        betHandler={handleBetPlace}
        setBetPayload={setBetPayload}
        game="soccer"
        gameEvent={gameEvent}
      />
    </div>
  );
}
